import React, { FunctionComponent, useState, useEffect } from 'react';
import * as ReactRedux from 'react-redux';
import { RootState, LoadingState, LoadingStatus } from 'src/types';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { permalinkFresh, datasetHasProjection } from 'src/selectors';
import CircularProgress from '@material-ui/core/CircularProgress';
import { savePermalink } from 'src/actions';
import { inDevMode } from 'src/util';
import PermalinkPrivacyWarningDialog from './PermalinkPrivacyWarningDialog';
import Popper from '@material-ui/core/Popper';

const useStyles = makeStyles((theme: Theme) => ({
  linkPopper: {
    zIndex: 11500,
    marginTop: 5,
  },
  linkPaper: {
    backgroundColor: '#222222',
    boxShadow: '1px 1px 1px #222 inset',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10,
  },
  linkText: {
    fontFamily: '"Lucida Console", Monaco, monospace',
    color: theme.palette.primary.light,
    fontSize: '0.85em',
    fontWeight: 'bold',
  },
  inButtonSpinner: {
    marginLeft: 5,
  },
}));

const InButtonSpinner: FunctionComponent<{}> = (props) => {
  const classes = useStyles();
  return <CircularProgress className={classes.inButtonSpinner} size={20} />;
};

interface SpinnerButtonProps extends ButtonProps {
  loading: boolean;
}

const SpinnerButton: FunctionComponent<SpinnerButtonProps> = (props) => {
  const { children, loading, ...rest } = props;
  return (
    <Button {...rest}>
      {children}
      {loading && <InButtonSpinner {...rest} />}
    </Button>
  );
};

interface PermalinkButtonProps {
  hasProjection: boolean;
  permalinkFresh: boolean;
  permalinkUrl: string;
  permalinkLoadingState: LoadingState;
  savePermalink: () => void;
}

const PermalinkControls: FunctionComponent<PermalinkButtonProps> = (props) => {
  const classes = useStyles();

  const [warningDialogOpen, setWarningDialogOpen] = useState<boolean>(false);

  const [copyComplete, setCopyComplete] = useState<boolean>(false);
  useEffect(() => {
    setCopyComplete(false);
  }, [props.permalinkUrl]);

  const ref = React.useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    setAnchorEl(ref.current);
  }, []);

  const onClickGet = () => {
    setWarningDialogOpen(true);
  };

  const onClickCopy = async () => {
    if (inDevMode) console.assert(props.permalinkUrl);
    try {
      await navigator.clipboard.writeText(props.permalinkUrl);
      setCopyComplete(true);
      setTimeout(() => {
        setCopyComplete(false);
      }, 750);
    } catch (error) {
      console.error(error);
    }
  };

  const onCloseWarning = () => {
    setWarningDialogOpen(false);
  };
  const onAcceptWarning = () => {
    setWarningDialogOpen(false);
    props.savePermalink();
  };

  const loading = props.permalinkLoadingState.status === LoadingStatus.Loading;

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={1}
    >
      <Grid item>
        <div ref={ref}>
          {props.permalinkFresh ? (
            <Button
              size={'small'}
              variant="outlined"
              color="primary"
              endIcon={
                copyComplete ? <DoneRoundedIcon /> : <AssignmentRoundedIcon />
              }
              onClick={onClickCopy}
            >
              Copy
            </Button>
          ) : (
            <SpinnerButton
              size={'small'}
              variant="outlined"
              color="primary"
              disabled={!props.hasProjection || loading}
              endIcon={!loading && <LinkRoundedIcon />}
              loading={loading}
              onClick={onClickGet}
            >
              Get link
            </SpinnerButton>
          )}
        </div>
      </Grid>
      {anchorEl && props.permalinkFresh && (
        <Popper
          className={classes.linkPopper}
          open={true}
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          <Paper className={classes.linkPaper}>
            <Typography className={classes.linkText}>
              {props.permalinkUrl}
            </Typography>
          </Paper>
        </Popper>
      )}
      {warningDialogOpen && (
        <PermalinkPrivacyWarningDialog
          onAccept={onAcceptWarning}
          onClose={onCloseWarning}
        />
      )}
    </Grid>
  );
};

const BoundPermalinkControls = ReactRedux.connect(
  (state: RootState) => ({
    hasProjection: datasetHasProjection(state),
    permalinkFresh: permalinkFresh(state),
    permalinkUrl: state.permalink.get('url'),
    permalinkLoadingState: state.loadingState.savePermalink,
  }),
  {
    savePermalink: savePermalink,
  }
)(PermalinkControls);

export default BoundPermalinkControls;
