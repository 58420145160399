import { List } from 'immutable';
import * as React from 'react';
import * as ReactRedux from 'react-redux';
import {
  Canvas,
  useFrame,
  useThree,
  extend,
  ReactThreeFiber,
} from 'react-three-fiber';
import * as _ from 'lodash';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as THREE from 'three';
import { PerspectiveCamera } from 'three';
import {
  OrbitControls,
  MapControls,
} from 'three/examples/jsm/controls/OrbitControls';

import { ActionType } from 'src/action-types';
import * as selectors from 'src/selectors';
import { DisplayPoint, Key, RootState } from 'src/types';

import ProjectionPoints from './ProjectionPoints';
import NebulaHeader from 'src/components/nebula-header/NebulaHeader';
import ControlsInfoPanel from '../controls-info-panel/ControlsInfoPanel';

extend({ OrbitControls });
extend({ MapControls });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      orbitControls: ReactThreeFiber.Object3DNode<
        OrbitControls,
        typeof OrbitControls
      >;
      mapControls: ReactThreeFiber.Object3DNode<
        MapControls,
        typeof MapControls
      >;
    }
  }
}

interface CameraProps {
  position?: THREE.Vector3;
  onCameraPositionChange?: (position: THREE.Vector3) => void;
  near: number;
}

function Camera(props: CameraProps) {
  const ref: React.RefObject<PerspectiveCamera> = React.useRef();
  const { setDefaultCamera } = useThree();
  // Make the camera known to the system
  const [prevPosition, setPrevPosition]: [
    THREE.Vector3,
    CallableFunction
  ] = React.useState(null);
  React.useEffect(
    () => void setDefaultCamera(ref.current as PerspectiveCamera),
    []
  );
  useFrame(() => {
    ref.current.updateMatrixWorld();
    if (prevPosition == null) {
      setPrevPosition(ref.current.position.clone());
      return;
    } else if (ref.current.position.distanceTo(prevPosition) !== 0) {
      const newPosition = ref.current.position.clone();
      props.onCameraPositionChange(newPosition);
      setPrevPosition(newPosition);
      return;
    }
  });
  return <perspectiveCamera ref={ref} {...props} />;
}

interface ControlsProps {
  dims: 2 | 3;
  onCameraPositionChange?: (position: THREE.Vector3) => void;
}

function Controls(props: ControlsProps) {
  const ref: React.RefObject<OrbitControls | MapControls> = React.useRef();
  const { camera, gl } = useThree();
  useFrame(() => {
    ref.current.update();
  });
  if (props.dims === 2) {
    return <mapControls ref={ref} args={[camera, gl.domElement]} {...props} />;
  } else {
    return (
      <orbitControls ref={ref} args={[camera, gl.domElement]} {...props} />
    );
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '#overlay-container': {
      position: 'absolute',
      width: '100%',
    },
    '#overlay-container > *': {
      position: 'absolute',
    },
  },
  projectionSurface: {
    position: 'static',
    flexGrow: 1,
    width: '100%',
    // top: 64,
    flex: '1 1 auto',
  },
  labelContainer: {
    // When using position: absolute, This prevents the label text from wrapping
    // before its min-width.
    width: '100%',
    pointerEvents: 'none',
  },
  projectionCanvasWrapper: {
    height: '100%',
  },
  projectionCanvas: {},
}));

export interface ProjectionCanvasProps {
  dims: 2 | 3;
  points?: List<DisplayPoint>;
  onHoveredPointChanged: (key: Key) => void;
  onPointClicked: (key: Key) => void;
}

export interface ProjectionCanvasState {
  cameraPosition?: THREE.Vector3;
}

// ProjectionCanvas is mostly responsible for rendering the Three-JS canvas and
// tracking the point hover and click state.
function ProjectionCanvas(props: ProjectionCanvasProps) {
  const classes = useStyles();
  const numPoints = props.points.size;
  const [cameraPosition, setCameraPosition] = React.useState<THREE.Vector3>(
    new THREE.Vector3(0, 2.5, 0)
  );
  React.useEffect(() => setCameraPosition(new THREE.Vector3(0, 2.5, 0)), [
    props.dims,
  ]);
  const labelContainerRef = React.useRef<HTMLDivElement>();
  const [pointerDown, setPointerDown] = React.useState<boolean>(false);

  const [hoveredPointKey, setHoveredPointKey] = React.useState<string>(null);
  const [pendingClick, setPendingClick] = React.useState<string>(null);

  // We prevent hovers while the pointer isn't over the canvas to avoid hovered
  // points sticking around while the pointer is over a label or something else
  // that doesn't pass through pointer events.
  const [pointerOverCanvas, setPointerOverCanvas] = React.useState<boolean>(
    false
  );

  // These useEffects below delay the dispatching of events until after the
  // paint. If the body of these useEffect handlers are moved into the body of
  // onPointHovered and onCanvasClicked, it seems like hovering doesn't reliably
  // work.
  const onPointHovered = (key: Key) => {
    if (!pointerOverCanvas) return;
    setHoveredPointKey(key);
  };
  React.useEffect(() => {
    props.onHoveredPointChanged(hoveredPointKey);
  }, [hoveredPointKey]);

  const onCanvasClicked = (e: any) => {
    if (hoveredPointKey) {
      e.stopPropagation();
      setPendingClick(hoveredPointKey);
    }
  };
  React.useEffect(() => {
    if (pendingClick) {
      props.onPointClicked(pendingClick);
      setPendingClick(null);
    }
  }, [pendingClick]);
  return (
    // This is a hack to work around Canvas not being able to propagate context
    // properly. See https://github.com/react-spring/react-three-fiber/issues/43
    // Apparently ReactReduxContext is not part of the public API, so they might
    // break this with updates.
    <ReactRedux.ReactReduxContext.Consumer>
      {({ store }) => (
        <div className={classes.projectionSurface}>
          <div id="overlay-container">
            <NebulaHeader />
            <ControlsInfoPanel />
            <div
              id="labelContainer"
              className={classes.labelContainer}
              ref={labelContainerRef}
            ></div>
          </div>
          <div
            className={classes.projectionCanvasWrapper}
            onClick={onCanvasClicked}
            onPointerDown={(e) => setPointerDown(true)}
            onPointerUp={(e) => setPointerDown(false)}
            onPointerEnter={(e) => {
              setPointerOverCanvas(true);
            }}
            onPointerOver={(e) => {
              setPointerOverCanvas(true);
            }}
            onPointerLeave={(e) => {
              setPointerOverCanvas(false);
              setHoveredPointKey(null);
            }}
          >
            <Canvas className={classes.projectionCanvas} noEvents>
              <ReactRedux.Provider store={store}>
                <Camera
                  position={cameraPosition}
                  onCameraPositionChange={(position) => {
                    setCameraPosition(position);
                  }}
                  near={0.0001}
                />
                <Controls dims={props.dims} />
                {numPoints > 0 && (
                  <ProjectionPoints
                    cameraPosition={cameraPosition}
                    dims={props.dims}
                    hoveredPointKey={hoveredPointKey}
                    onPointHovered={onPointHovered}
                    onPointClosed={(key: string) => {
                      // This is to make sure that when the close button is
                      // clicked on a label, the other points become
                      // interactable again even if the onPointerEnter event
                      // doesn't fire.
                      setPointerOverCanvas(true);
                      props.onPointClicked(key);
                    }}
                    pointerDown={pointerDown}
                    labelContainerRef={labelContainerRef}
                  />
                )}
              </ReactRedux.Provider>
            </Canvas>
          </div>
        </div>
      )}
    </ReactRedux.ReactReduxContext.Consumer>
  );
}

export default ReactRedux.connect(
  (state: RootState) => ({
    points: selectors.displayPoints(state),
    dims: selectors.datasetProjectionDims(state),
  }),
  (dispatch) => ({
    onPointClicked: (key: Key) =>
      dispatch({
        type: ActionType.PointClicked,
        key: key,
      }),
    onHoveredPointChanged: (key: Key) =>
      dispatch({
        type: ActionType.HoveredPointChanged,
        key: key,
      }),
  })
)(ProjectionCanvas);
