import { UMAP } from 'umap-js';
import * as _ from 'lodash';

import { ProjectionType } from 'src/types';

const scaleToUnitCube = (vecs: number[][], dims: number): number[][] => {
  const mean = _.fill(new Array(dims), 0);
  const max = _.fill(new Array(dims), Number.NEGATIVE_INFINITY);
  const min = _.fill(new Array(dims), Number.POSITIVE_INFINITY);
  for (let k = 0; k < vecs.length; k++) {
    const vec = vecs[k];
    for (let d = 0; d < dims; d++) {
      // Incremental mean update
      mean[d] += (1 / (k + 1)) * (vec[d] - mean[d]);
      if (vec[d] > max[d]) {
        max[d] = vec[d];
      }
      if (vec[d] < min[d]) {
        min[d] = vec[d];
      }
    }
  }
  const scaled: number[][] = Array(vecs.length);
  for (let k = 0; k < vecs.length; k++) {
    const vec = vecs[k];
    scaled[k] = new Array(dims);
    for (let d = 0; d < dims; d++) {
      const range = max[d] - min[d];
      const midpoint = (max[d] + min[d]) / 2;
      scaled[k][d] = (2 * (vec[d] - midpoint)) / range;
    }
  }
  return scaled;
};

export async function projectDataset(
  type: ProjectionType,
  vectors: number[][]
): Promise<number[][]> {
  const dims = type === ProjectionType.Umap2D ? 2 : 3;
  const umap = new UMAP({
    nComponents: dims,
    minDist: 0.01,
    nNeighbors: Math.min(vectors.length - 1, 15),
  });
  const umapProjection: number[][] = await umap.fitAsync(vectors);
  return scaleToUnitCube(umapProjection, dims);
}
