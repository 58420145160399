import React, { FunctionComponent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TitleControls from '../title-controls/TitleControls';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: '15px 0px 0px 20px',
    zIndex: 5500,
  },
}));

interface NebulaHeaderProps {}

const NebulaHeader: FunctionComponent<NebulaHeaderProps> = () => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <TitleControls />
    </Box>
  );
};

export default NebulaHeader;
