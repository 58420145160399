import { createSelector } from 'reselect';
import { Map, List, Seq } from 'immutable';
import * as THREE from 'three';

import { CURRENT_STATE_SERIALIZATION_VERSION } from 'src/constants';
import {
  RootState,
  Datapoint,
  DisplayPoint,
  DisplayStatus,
  Key,
  ProjectionView,
  SavableState,
  Dataset,
  ProjectionParams,
  PermalinkState,
  UserAlert,
} from 'src/types';
import { deleteEmbeddings, defaultColorForItem, nDistinctColors } from './util';

export const rootDataset = (state: RootState): Dataset => state.dataset;
export const projectionView = (state: RootState): ProjectionView =>
  state.projectionView;
export const projectionParams = (state: RootState): ProjectionParams =>
  state.projectionParams;
export const permalink = (state: RootState): PermalinkState => state.permalink;

export const points = (state: RootState): Seq.Indexed<Datapoint> =>
  state.dataset.points;

export const firstPoint = (state: RootState): Datapoint =>
  state.dataset.points.first(null);

export const keyedPoints = (state: RootState): Seq.Keyed<Key, Datapoint> =>
  state.dataset.keyedPoints;

export const alerts = (state: RootState): Map<string, UserAlert> =>
  state.alerts;

export const datasetHasEmbedding = createSelector(
  points,
  (points: Seq.Indexed<Datapoint>) => {
    return points.size !== 0 && points.first<Datapoint>().embedding != null;
  }
);

export const datasetHasProjection = createSelector(
  firstPoint,
  projectionParams,
  (point: Datapoint, projectionParams: ProjectionParams) => {
    return point && point.projections.get(projectionParams.type) != null;
  }
);

export const datasetProjectionDims = createSelector(
  datasetHasProjection,
  projectionParams,
  firstPoint,
  (
    hasProjection: boolean,
    projectionParams: ProjectionParams,
    point: Datapoint
  ) => {
    if (hasProjection) {
      return point.projections.get(projectionParams.type).size;
    }
    return null;
  }
);

// TODO: Consider using reselect-map to avoid recomputing when only one
// datapoint changes. Will require refactoring selectors so that the first
// dependency is a selector for a single sequence of elements containing each
// Datapoint AND all other data, like hovered status, that determines the
// DisplayPoint.
export const displayPoints = createSelector(
  rootDataset,
  projectionParams,
  datasetHasProjection,
  (
    rootDataset: Dataset,
    projectionParams: ProjectionParams,
    datasetHasProjection: boolean
  ) => {
    if (!datasetHasProjection) {
      return List([]);
    }
    const datasets = rootDataset.datasets.toList();
    const colors = nDistinctColors(datasets.size);

    const asDisplayPoint = (
      key: Key,
      datapoint: Datapoint,
      dataset: Dataset,
      datasetIndex: number
    ): DisplayPoint => {
      const projection = datapoint.projections
        .get(projectionParams.type)
        .toArray();
      let position: THREE.Vector3;
      if (projection.length === 2) {
        // We do this in a weird order to fit the order the MapControls are
        // initially set up for.
        position = new THREE.Vector3(projection[0], 0, projection[1]);
      } else {
        position = new THREE.Vector3(
          projection[0],
          projection[2],
          projection[1]
        );
      }

      const colorString =
        dataset.annotations.color ||
        defaultColorForItem(datapoint.item) ||
        colors[datasetIndex % colors.length];
      const color = new THREE.Color(colorString);

      return new DisplayPoint({
        key: key,
        position: position,
        item: datapoint.item,
        color,
      });
    };

    return List().withMutations((list) => {
      for (let i = 0; i < datasets.size; i++) {
        const dataset = datasets.get(i);
        list = list.concat(
          dataset.directPoints
            .map((datapoint, key) => asDisplayPoint(key, datapoint, dataset, i))
            .valueSeq()
        );
      }
      return list;
    });
  }
);

export const savableRootDataset = createSelector(
  rootDataset,
  (dataset: Dataset) => dataset.mapPoints((p) => p.set('embedding', null))
);

export const savableProjectionView = createSelector(
  projectionView,
  // Stripping this allows us to avoid recomputing the savableState with each
  // hover.
  (projectionView: ProjectionView) => projectionView.set('hoveredPoint', null)
);

export const savableState = createSelector(
  savableRootDataset,
  savableProjectionView,
  projectionParams,
  (
    dataset: Dataset,
    projectionView: ProjectionView,
    projectionParams: ProjectionParams
  ) => {
    return new SavableState({
      serializationVersion: CURRENT_STATE_SERIALIZATION_VERSION,
      dataset,
      projectionView: projectionView.set('hoveredPoint', null),
      projectionParams,
    });
  }
);

export const savableStateNoEmbeddings = createSelector(
  savableState,
  (state: SavableState) => {
    return state.update('dataset', deleteEmbeddings);
  }
);

// Does the current permalink reflect the current state?
export const permalinkFresh = createSelector(
  permalink,
  savableStateNoEmbeddings,
  (permalink: PermalinkState, savableState: SavableState) => {
    return (
      permalink &&
      permalink.savedState &&
      savableState &&
      (permalink.savedState === savableState ||
        permalink.savedState.equals(savableState))
    );
  }
);

// TODO: Could make this filter to at most one alert with the same contents
export const activeAlerts = createSelector(
  alerts,
  (alerts: Map<string, UserAlert>) => {
    const alertList = alerts.toList();
    return alertList.sortBy((alert) => -alert.timestamp);
  }
);
