import { Location } from 'history';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { useRouteMatch, match, useLocation } from 'react-router-dom';

import { extractNumPoints } from 'src/util';
import DatasetControlForm from './DatasetControlForm';
import { NumPointsSelect } from './NumPointsSelect';

interface TwitterTimelineControlsProps {
  fetchDataset: (path: string) => void;
  setPath: (path: string) => void;
}

const toPath = (username: string, numPoints: number): string =>
  `/twitter-timeline/${username}?n=${numPoints}`;

export default (props: TwitterTimelineControlsProps) => {
  const routeMatch: match = useRouteMatch();
  const username = (routeMatch.params as any)['username'] || null;
  const [usernameInput, setUsernameInput] = useState<string>(
    username || 'BBCBreaking'
  );

  const location: Location = useLocation();
  const numPoints: number = extractNumPoints(location);
  const [numPointsInput, setNumPointsInput] = useState<number>(
    numPoints || 128
  );

  useEffect(() => {
    if (username && numPoints) {
      props.fetchDataset(toPath(username, numPoints));
    }
  }, [routeMatch]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (usernameInput && numPointsInput) {
      props.setPath(toPath(usernameInput, numPointsInput));
    }
  };
  return (
    <DatasetControlForm onSubmit={onSubmit}>
      <TextField
        id="twitter-timeline-handle-input"
        className="timeline-handle-input"
        label="Twitter handle"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={usernameInput}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setUsernameInput(e.target.value);
        }}
      />
      <NumPointsSelect
        numPoints={numPointsInput}
        setNumPoints={setNumPointsInput}
      />
    </DatasetControlForm>
  );
};
