import { ProjectionParams, ProjectionType } from 'src/types';
import React, { FunctionComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  activeDimLabel: {
    color: theme.palette.primary.light,
  },
  inactiveDimLabel: {
    color: theme.palette.text.disabled,
  },
}));

interface ProjectionControlsProps {
  params: ProjectionParams;
  setParams: (params: ProjectionParams) => void;
}

const ProjectionControls: FunctionComponent<ProjectionControlsProps> = (
  props: ProjectionControlsProps
) => {
  const classes = useStyles();
  const toggleDims = () => {
    return props.setParams(
      props.params.set(
        'type',
        props.params.type === ProjectionType.Umap2D
          ? ProjectionType.Umap3D
          : ProjectionType.Umap2D
      )
    );
  };
  return (
    <Grid component="label" container alignItems="center" spacing={1}>
      <Grid item>
        <Typography
          component="div"
          className={
            props.params.type === ProjectionType.Umap2D
              ? classes.activeDimLabel
              : classes.inactiveDimLabel
          }
        >
          {'2D'}
        </Typography>
      </Grid>
      <Grid item>
        <Switch
          checked={props.params.type === ProjectionType.Umap3D}
          // TODO: This listener is slow for some reason. See Chrome dev console
          // output.
          onChange={toggleDims}
          name="dimSwitch"
          color="default"
        />
      </Grid>
      <Grid item>
        <Typography
          component="div"
          className={
            props.params.type === ProjectionType.Umap3D
              ? classes.activeDimLabel
              : classes.inactiveDimLabel
          }
        >
          {'3D'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProjectionControls;
