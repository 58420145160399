import React, { useState, FunctionComponent } from 'react';
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { SplitMode, parseAsDatapoints } from 'src/parsing/parse';
import DatasetControlForm from './DatasetControlForm';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UserAlert, FreeformDatasetInput } from 'src/types';
import PrimaryButton from '../generic/PrimaryButton';
import SecondaryButton from '../generic/SecondaryButton';
import { MAX_POINTS_EMBED } from 'src/constants';
import { List } from 'immutable';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

const useStyles = makeStyles((theme: Theme) => ({
  entryButton: {
    textTransform: 'none',
    minWidth: 220,
    padding: theme.spacing(1.5),
    borderColor: theme.palette.primary.main,
    color: theme.palette.text.hint,
    fontSize: '1.1em',
    borderStyle: 'dashed',
  },
  placeholderText: {
    color: theme.palette.text.hint,
  },
  dialogRoot: {},
  dialogPaperRoot: {
    backgroundColor: theme.palette.grey[900],
  },
  dialogContentText: {
    color: theme.palette.text.primary,
    fontSize: '1.1em',
    marginTop: 15,
  },
  numPointsDisplay: {
    color: theme.palette.text.primary,
  },
  truncationWarning: {
    color: theme.palette.warning.light,
  },
  datasetEntryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
    flexBasis: '20%',
    flexShrink: 0,
  },
  datasetEntrySecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: '70%',
    flexShrink: 0,
  },
  datasetEntryContainer: {
    marginBottom: 10,
    flexBasis: '10%',
    flexShrink: 0,
  },
}));

const DatasetFormAccordion = withStyles((theme: Theme) => ({
  root: {
    border: '1px solid rgba(255, 255, 255, 0.5)',
    backgroundColor: theme.palette.grey[900],
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(Accordion);

const DatasetFormAccordionSummary = withStyles((theme: Theme) => ({
  expanded: {
    '&$expanded': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
}))(AccordionSummary);

const newFreeformDatasetInput = (): FreeformDatasetInput =>
  new FreeformDatasetInput({ splitMode: SplitMode.LINE, text: '' });

interface SingleDatasetEntryFormProps {
  open: boolean;
  index: number;
  onAccordionChange: () => void;
  datasetInput: FreeformDatasetInput;
  setDatasetInput: (input: FreeformDatasetInput) => void;
  datasetInputs: List<FreeformDatasetInput>;
  deleteDatasetInput: () => void;
}

const SingleDatasetEntryForm: FunctionComponent<SingleDatasetEntryFormProps> = (
  props
) => {
  const classes = useStyles();
  const datasetInput = props.datasetInput;
  const numPoints = props.datasetInput.numPoints;
  const deleteDisabled = props.datasetInputs.size <= 1;
  return (
    <DatasetFormAccordion
      expanded={props.open}
      key={props.index}
      onChange={props.onAccordionChange}
    >
      <DatasetFormAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container direction="row" alignItems="center">
          <Typography className={classes.datasetEntryHeading}>
            {`Dataset ${props.index + 1}`}
          </Typography>
          <Typography className={classes.datasetEntrySecondaryHeading}>
            {`${numPoints} datapoint${numPoints === 1 ? '' : 's'} detected`}
          </Typography>
          <IconButton
            disabled={deleteDisabled}
            onClick={props.deleteDatasetInput}
          >
            <DeleteForeverRoundedIcon
              fontSize="small"
              color={deleteDisabled ? 'disabled' : 'error'}
            />
          </IconButton>
        </Grid>
      </DatasetFormAccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" justify="flex-start" spacing={2}>
          <Grid
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={0}
          >
            <Grid item>
              <FormControl variant="outlined">
                <InputLabel id="split-mode-select-label">Split by</InputLabel>
                <Select
                  labelId="split-mode-select-label"
                  id="split-mode-select-input"
                  value={datasetInput.splitMode}
                  onChange={(e: React.ChangeEvent<{ value: SplitMode }>) => {
                    props.setDatasetInput(
                      datasetInput.set('splitMode', e.target.value)
                    );
                  }}
                  label="Split mode"
                >
                  <MenuItem value={SplitMode.LINE}>Lines</MenuItem>
                  <MenuItem value={SplitMode.SENTENCE}>Sentences</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              id="free-text-dialog-title"
              label="Paste text here"
              multiline
              fullWidth
              rows={10}
              variant="outlined"
              value={datasetInput.text}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                props.setDatasetInput(datasetInput.set('text', e.target.value));
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </DatasetFormAccordion>
  );
};

interface TextCaptureDialogProps {
  datasetInputs: List<FreeformDatasetInput>;
  setDatasetInputs: (inputs: List<FreeformDatasetInput>) => void;
  totalNumPoints: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  load: () => void;
}

const TextCaptureDialog = (props: TextCaptureDialogProps) => {
  const classes = useStyles();
  const handleClose = () => {
    props.setOpen(false);
  };
  const [openDatasetIndex, setOpenDatasetIndex] = useState<number>(0);
  const onAccordionChange = (index: number) => {
    if (index === openDatasetIndex) {
      setOpenDatasetIndex(null);
    } else {
      setOpenDatasetIndex(index);
    }
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      className={classes.dialogRoot}
      PaperProps={{
        classes: { root: classes.dialogPaperRoot },
      }}
    >
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          Enter text below, one entry per line. Try pasting in a spreadsheet
          column.
        </DialogContentText>
        <div className={classes.datasetEntryContainer}>
          {props.datasetInputs.map((datasetInput, index) => (
            <SingleDatasetEntryForm
              key={index}
              open={openDatasetIndex === index}
              index={index}
              onAccordionChange={() => onAccordionChange(index)}
              datasetInput={datasetInput}
              datasetInputs={props.datasetInputs}
              deleteDatasetInput={() =>
                props.setDatasetInputs(props.datasetInputs.delete(index))
              }
              setDatasetInput={(input) =>
                props.setDatasetInputs(props.datasetInputs.set(index, input))
              }
            />
          ))}
        </div>
        <Grid container direction="column" justify="flex-start" spacing={1}>
          <Grid item>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              endIcon={<AddCircleRoundedIcon />}
              onClick={() => {
                props.setDatasetInputs(
                  props.datasetInputs.push(newFreeformDatasetInput())
                );
              }}
            >
              Add dataset
            </Button>
          </Grid>
          <Grid item container direction="row" justify="flex-start" spacing={2}>
            <Grid item>
              <Typography className={classes.numPointsDisplay}>{`${
                props.totalNumPoints
              } total datapoint${
                props.totalNumPoints === 1 ? '' : 's'
              } detected.`}</Typography>
            </Grid>
            {props.totalNumPoints > MAX_POINTS_EMBED && (
              <Grid item>
                <Typography className={classes.truncationWarning}>
                  {`Only the first ${MAX_POINTS_EMBED} will be used.`}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <DialogContentText className={classes.dialogContentText}>
          All processing happens in your browser. Clicking load doesn't upload
          your data to our servers (or anyone else's!).
        </DialogContentText>
        <DialogActions>
          <SecondaryButton onClick={handleClose}>Close</SecondaryButton>
          <PrimaryButton
            onClick={() => {
              props.load();
              handleClose();
            }}
          >
            Load
          </PrimaryButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

interface FreeformTextControlsProps {
  setPath: (path: string) => void;
  loadFreeformDataset: (inputs: List<FreeformDatasetInput>) => void;
  displayAlert: (alert: UserAlert, durationMs?: number) => void;
}

export default (props: FreeformTextControlsProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = (e: any) => {
    setOpen(!open);
  };

  const [datasetInputs, setDatasetInputs] = useState<
    List<FreeformDatasetInput>
  >(List([newFreeformDatasetInput()]));
  const totalNumPoints = datasetInputs
    .map((di) => di.numPoints)
    .reduce((val, acc) => val + acc, 0);

  const onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (totalNumPoints < 3) {
      props.displayAlert(
        new UserAlert({
          title: 'Must have at least 3 points.',
          description: 'Works best with 50-1500 points.',
        })
      );
      return;
    }
    props.setPath('/new-nebula');
    props.loadFreeformDataset(datasetInputs);
  };
  return (
    <React.Fragment>
      <DatasetControlForm onSubmit={onSubmit}>
        <Button
          variant="outlined"
          className={classes.entryButton}
          onClick={toggleOpen}
        >
          {totalNumPoints === 0
            ? 'Click to add text'
            : `${totalNumPoints} datapoints`}
        </Button>
        <TextCaptureDialog
          open={open}
          setOpen={setOpen}
          datasetInputs={datasetInputs}
          setDatasetInputs={setDatasetInputs}
          totalNumPoints={totalNumPoints}
          load={onSubmit}
        />
      </DatasetControlForm>
    </React.Fragment>
  );
};
