import React, { FunctionComponent } from 'react';
import { List } from 'immutable';
import { UserAlert, RootState } from 'src/types';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import { activeAlerts } from 'src/selectors';
import { ActionType, DisplayAlertEnd } from 'src/action-types';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
}));

interface AlertDisplayProps {
  activeAlerts: List<UserAlert>;
  closeAlert: (id: string) => void;
}

const AlertDisplay: FunctionComponent<AlertDisplayProps> = (props) => {
  const classes = useStyles();
  const onCloseClicked = (id: string) => {
    props.closeAlert(id);
  };
  return (
    <React.Fragment>
      {props.activeAlerts.map((alert: UserAlert) => {
        return (
          <Snackbar key={alert.id} open={true}>
            <Alert
              className={classes.alert}
              onClose={() => onCloseClicked(alert.id)}
              severity={alert.severity}
              variant="filled"
            >
              <AlertTitle>{alert.title}</AlertTitle>
              {alert.description}
            </Alert>
          </Snackbar>
        );
      })}
    </React.Fragment>
  );
};

export default connect(
  (state: RootState) => ({
    activeAlerts: activeAlerts(state),
  }),
  {
    closeAlert: (id: string): DisplayAlertEnd => ({
      type: ActionType.DisplayAlertEnd,
      id: id,
    }),
  }
)(AlertDisplay);
