import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import React, { FunctionComponent, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import discordLogoSvgUrl from 'src/assets/img/Discord-Logo+Wordmark-Color.svg';
import { getLocalStorageFlag, setLocalStorageFlag } from 'src/util';
import { LOCAL_STORAGE_KEY_INFO_PANEL_CLOSED } from 'src/constants';
import A from '../generic/A';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoPanelIcon: {
      color: theme.palette.primary.main,
    },
    infoPanel: {
      zIndex: 12500,
      maxWidth: 300,
      marginTop: 10,
    },
    infoPanelPaper: {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.text.primary,
    },
    closeButton: {
      float: 'right',
    },
    typography: {
      padding: theme.spacing(1),
    },
    discordLink: {
      textAlign: 'center',
      paddingRight: 20,
      display: 'block',
    },
    discordIcon: {
      height: 50,
    },
    discordIconRoot: {},
  })
);

interface PProps {}

// Paragraph-like component
const P: FunctionComponent<PProps> = (props) => {
  const classes = useStyles();
  return (
    <Typography
      variant="body1"
      className={classes.typography}
      component="div"
      {...props}
    ></Typography>
  );
};

interface InfoButtonProps {}

export const InfoButton: FunctionComponent<InfoButtonProps> = () => {
  const classes = useStyles();
  const ref = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState<boolean>(
    !getLocalStorageFlag(LOCAL_STORAGE_KEY_INFO_PANEL_CLOSED, false)
  );

  useEffect(() => {
    setAnchorEl(ref.current);
  }, []);

  function handleClick() {
    if (open) {
      setLocalStorageFlag(LOCAL_STORAGE_KEY_INFO_PANEL_CLOSED, true);
    }
    setOpen(!open);
  }
  const id = open ? 'info-popover' : undefined;

  return (
    <div ref={ref}>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <HelpOutlineRoundedIcon
          className={classes.infoPanelIcon}
          fontSize="large"
        />
      </IconButton>
      {anchorEl && (
        <Popper
          className={classes.infoPanel}
          open={open}
          anchorEl={anchorEl}
          placement="left-start"
          transition
          onClick={handleClick}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={150}>
              <Paper className={classes.infoPanelPaper}>
                <div className={classes.closeButton}>
                  <IconButton color="secondary">
                    <CloseRoundedIcon />
                  </IconButton>
                </div>
                <P>This is a tool for exploring text.</P>
                <P>
                  It uses a{' '}
                  <A href="https://arxiv.org/abs/1803.11175">
                    machine learning model
                  </A>{' '}
                  to convert text into points in a high dimensional space.
                </P>
                <P>
                  It then projects those points down into 2D or 3D space with an
                  algorithm called{' '}
                  <A href="https://arxiv.org/abs/1802.03426">UMAP</A>.
                </P>
                <P>
                  Questions? Feedback? Email{' '}
                  <A href="mailto:support@nebulate.ai">support@nebulate.ai</A>{' '}
                  or Hop into our Discord:
                </P>
                <A
                  className={classes.discordLink}
                  href="https://discord.gg/KEcSEAh"
                >
                  <Icon classes={{ root: classes.discordIconRoot }}>
                    <img
                      className={classes.discordIcon}
                      src={discordLogoSvgUrl}
                    />
                  </Icon>
                </A>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </div>
  );
};
