import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { RootState } from 'src/types';
import { setTitle } from 'src/actions';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { datasetHasProjection, rootDataset } from 'src/selectors';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme: Theme) => ({
  titleWrapper: {},
  titleInputForm: {
    top: 0,
    left: 0,
    position: 'absolute',
  },
  titleTextField: {},
  titleInput: {
    fontSize: '1.5em',
  },
  titleInputLabel: {
    fontSize: '1.5em',
  },
  noneditableTitle: {
    whiteSpace: 'pre-wrap',
    fontSize: '1.75em',
    maxWidth: 700,
    color: theme.palette.text.primary,
  },
  '@global': {
    '.editable-on-hover': {
      position: 'relative',
    },
    '.editable-on-hover > :nth-child(1)': {
      visibility: 'visible',
    },
    '.editable-on-hover:hover > :nth-child(1)': {
      visibility: 'hidden',
    },
    '.editable-on-hover > :nth-child(2)': {
      visibility: 'hidden',
    },
    '.editable-on-hover:hover > :nth-child(2)': {
      visibility: 'visible',
    },
  },
}));

interface TitleControlsProps {
  title: string;
  setTitle: (title: string) => void;
  datasetHasProjection: boolean;
}

const TitleControls: FunctionComponent<TitleControlsProps> = (props) => {
  // When there's no title, we want this to be visible, but small
  // Until it's edited by the user - then we want it to switch to the editable
  // on hover behavior.
  if (!props.datasetHasProjection) return null;
  const classes = useStyles();

  const title = props.title;
  const [titleInput, setTitleInput] = useState<string>(title || '');
  useEffect(() => {
    setTitleInput(props.title);
  }, [props.title]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleInput(e.target.value);
  };
  const commitTitle = () => {
    props.setTitle(titleInput);
  };
  return (
    <div className={`${classes.titleWrapper} ${title && 'editable-on-hover'}`}>
      <Typography className={classes.noneditableTitle}>{title}</Typography>
      <form
        className={classes.titleInputForm}
        onSubmit={(e: any) => {
          commitTitle();
          e.preventDefault();
        }}
      >
        <FormControl variant="outlined">
          <TextField
            label="Title"
            className={classes.titleTextField}
            style={{
              width: titleInput?.length > 0 ? 500 : 250,
            }}
            variant="outlined"
            InputProps={{
              className: classes.titleInput,
            }}
            InputLabelProps={{
              className: classes.titleInputLabel,
            }}
            value={titleInput || ''}
            onChange={onChange}
            onBlur={commitTitle}
          />
        </FormControl>
      </form>
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    title: rootDataset(state).annotations.get('title'),
    datasetHasProjection: datasetHasProjection(state),
  }),
  {
    setTitle: setTitle,
  }
)(TitleControls);
