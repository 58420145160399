import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Button from '@material-ui/core/Button';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datasetControls: {
      marginTop: 4,
    },
    loadButton: {},
  })
);

interface DatasetControlFormProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const DatasetControlForm: FunctionComponent<DatasetControlFormProps> = (
  props
) => {
  const classes = useStyles();

  return (
    <form onSubmit={(e) => props.onSubmit(e)}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={1}
      >
        {React.Children.map(props.children, (child: JSX.Element) => (
          <Grid item>{child}</Grid>
        ))}
        <Grid item>
          <Button
            id="dataset-load-button"
            type="submit"
            className={classes.loadButton}
            variant="contained"
            color="primary"
          >
            Load
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DatasetControlForm;
