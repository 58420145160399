import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch, match } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';

import * as actions from 'src/actions';
import DatasetControlForm from './DatasetControlForm';

interface SavedNebulaControlsProps {
  loadPermalink: (id: string) => void;
  setPath: (path: string) => void;
}

const SavedNebulaControls = (props: SavedNebulaControlsProps) => {
  const routeMatch: match = useRouteMatch();
  const saveId = (routeMatch.params as any)['saveId'] || null;

  useEffect(() => {
    if (saveId) {
      props.loadPermalink(`s/${saveId}`);
    }
  }, [routeMatch]);

  const [idInput, setIdInput]: [string, CallableFunction] = React.useState(
    saveId || ''
  );

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const path = `/s/${idInput}`;
    props.setPath(path);
  };
  return (
    <DatasetControlForm onSubmit={onSubmit}>
      <TextField
        id="nebula-id-input"
        label="Nebula ID"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={idInput}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setIdInput(e.target.value);
        }}
      />
    </DatasetControlForm>
  );
};

export default connect(null, {
  loadPermalink: actions.loadPermalink,
})(SavedNebulaControls);
