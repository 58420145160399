import React from 'react';
import { RedditPost } from '@nebula/common';
import {
  CommonLabelContent,
  PointLabelContent,
  ContextLink,
} from './PointLabelContent';

const RedditPostLabelContent: PointLabelContent = (props) => {
  const item = props.point.item as RedditPost;
  return (
    <CommonLabelContent point={props.point}>
      <ContextLink href={item.submissionUrl}>Link</ContextLink>
      <ContextLink href={item.canonicalUrl}>
        {`${item.numComments} comments`}
      </ContextLink>
    </CommonLabelContent>
  );
};

export default RedditPostLabelContent;
