import * as Redux from 'redux';
import * as _ from 'lodash';
import { List, Map, Set } from 'immutable';

import {
  ActionType,
  Action,
  UpdateDatasetAction,
  EmbedWorkerStart,
  FetchDatasetComplete,
  PointClicked,
  SetProjectionParams,
  HoveredPointChanged,
  FirebaseInitComplete,
  SavePermalinkComplete,
  LoadPermalinkComplete,
  ComputeProjectionComplete,
  SetDatasetAnnotations,
  DisplayAlert,
  DisplayAlertEnd,
} from 'src/action-types';
import {
  Dataset,
  RootState,
  ProjectionView,
  LoadingState,
  LoadingStatus,
  Key,
  ProjectionParams,
  PermalinkState,
  DatasetAnnotations,
  UserAlert,
} from 'src/types';
import WebpackWorker from 'worker-loader!*';
import { permalinkIdToLink, mergeProjection } from './util';

const isList = List.isList;

type Reducer<T> = (state: T, action: Action) => T;

type ActionHandlerMap<T> = {
  [action in ActionType]?: Reducer<T>;
};

function createReducer<StateT>(
  initialState: StateT,
  handlers: ActionHandlerMap<StateT>
): Reducer<StateT> {
  return function reducer(state: StateT = initialState, action: Action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
}

function mergeImmutable(originalVal: any, newVal: any): any {
  if (
    originalVal &&
    originalVal.mergeWith &&
    !isList(originalVal) &&
    !isList(newVal)
  ) {
    return originalVal.mergeWith(mergeImmutable, newVal);
  }
  return newVal;
}

function replaceDataset(
  _dataset: Dataset,
  action: UpdateDatasetAction
): Dataset {
  return action.dataset;
}

const reduceDataset: Reducer<Dataset> = createReducer(
  new Dataset({ directPoints: Map() }),
  {
    [ActionType.LoadFreeTextDatasetComplete]: replaceDataset,
    [ActionType.FetchDatasetComplete]: replaceDataset,
    [ActionType.SetDatasetAnnotations]: (
      state: Dataset,
      action: SetDatasetAnnotations
    ) =>
      state.update('annotations', (a: DatasetAnnotations) =>
        mergeImmutable(a, action.annotations)
      ),
    [ActionType.ComputeEmbeddingComplete]: replaceDataset,
    [ActionType.ComputeProjectionComplete]: (
      state: Dataset,
      action: ComputeProjectionComplete
    ) =>
      state.setInPoints(
        ['projections', action.projectionType],
        action.projections
      ),
    [ActionType.LoadPermalinkComplete]: (
      _dataset: Dataset,
      action: LoadPermalinkComplete
    ) => {
      return action.savedState.dataset;
    },
  }
);

const reduceEmbedWorker: Reducer<WebpackWorker> = createReducer(null, {
  [ActionType.EmbedWorkerStart]: (_embed_worker, action: EmbedWorkerStart) =>
    action.worker,
});

const reduceProjectionParams: Reducer<ProjectionParams> = createReducer(
  new ProjectionParams(),
  {
    [ActionType.SetProjectionParams]: (_params, action: SetProjectionParams) =>
      action.params,
    [ActionType.LoadPermalinkComplete]: (
      _params,
      action: LoadPermalinkComplete
    ) => action.savedState.projectionParams,
  }
);

const randomPoints = (dataset: Dataset, n: number): Key[] => {
  const shuffledKeys: Key[] = _.shuffle(dataset.keyedPoints.keySeq().toArray());
  const selectedKeys: Key[] = _.slice(shuffledKeys, 0, n);
  return selectedKeys;
};

const reduceProjectionView: Reducer<ProjectionView> = createReducer(
  new ProjectionView(),
  {
    [ActionType.LoadFreeTextDatasetComplete]: (
      state: ProjectionView,
      action: FetchDatasetComplete
    ) => {
      return state.set('ajarPoints', Set(randomPoints(action.dataset, 12)));
    },
    [ActionType.FetchDatasetComplete]: (
      state: ProjectionView,
      action: FetchDatasetComplete
    ) => {
      return state.set('ajarPoints', Set(randomPoints(action.dataset, 12)));
    },
    [ActionType.PointClicked]: (
      state: ProjectionView,
      action: PointClicked
    ) => {
      if (state.openPoints.contains(action.key)) {
        state = state.set('openPoints', state.openPoints.delete(action.key));
        state = state.set('ajarPoints', state.ajarPoints.delete(action.key));
      } else {
        state = state.set('openPoints', state.openPoints.add(action.key));
      }
      return state;
    },
    [ActionType.HoveredPointChanged]: (
      state: ProjectionView,
      action: HoveredPointChanged
    ) => {
      state = state.set('hoveredPoint', action.key);
      return state;
    },
    [ActionType.LoadPermalinkComplete]: (
      state: ProjectionView,
      action: LoadPermalinkComplete
    ) => {
      return action.savedState.projectionView;
    },
  }
);

const reduceFirebaseApp = createReducer(null, {
  [ActionType.FirebaseInitComplete]: (
    state: firebase.app.App,
    action: FirebaseInitComplete
  ) => {
    return action.firebaseApp;
  },
});

const createLoadingStatusReducer = (
  begin: ActionType,
  fail: ActionType,
  finish: ActionType
): Reducer<LoadingState> =>
  createReducer<LoadingState>(
    new LoadingState({
      status: LoadingStatus.Ready,
    }),
    {
      [begin]: (state: LoadingState, action: Action) => ({
        status: LoadingStatus.Loading,
      }),
      [fail]: (state: LoadingState, action: any) => ({
        status: LoadingStatus.Ready,
        error: action.error,
      }),
      [finish]: (state: LoadingState, action: Action) => ({
        status: LoadingStatus.Ready,
      }),
    }
  );

const reducePermalinkState = createReducer(new PermalinkState(), {
  [ActionType.SavePermalinkComplete]: (
    state: PermalinkState,
    action: SavePermalinkComplete
  ) => {
    return new PermalinkState({
      url: action.url,
      savedState: action.savedState,
    });
  },
  [ActionType.LoadPermalinkComplete]: (
    state: PermalinkState,
    action: LoadPermalinkComplete
  ) => {
    return new PermalinkState({
      url: permalinkIdToLink(action.id),
      savedState: action.savedState,
    });
  },
});

const reduceAlerts = createReducer(Map<string, UserAlert>(), {
  [ActionType.DisplayAlert]: (
    state: Map<string, UserAlert>,
    action: DisplayAlert
  ) => state.set(action.alert.id, action.alert),
  [ActionType.DisplayAlertEnd]: (
    state: Map<string, UserAlert>,
    action: DisplayAlertEnd
  ) => state.delete(action.id),
});

export const reduceRoot: Reducer<RootState> = Redux.combineReducers({
  dataset: reduceDataset,
  embedWorker: reduceEmbedWorker,
  projectionParams: reduceProjectionParams,
  projectionView: reduceProjectionView,
  loadingState: Redux.combineReducers({
    // TODO: Switch to generic loading actions?
    // Would be nice not to need separate actions for everything that can be
    // loaded.
    dataset: createLoadingStatusReducer(
      ActionType.FetchDataset,
      ActionType.FetchDatasetFailed,
      ActionType.FetchDatasetComplete
    ),
    embedding: createLoadingStatusReducer(
      ActionType.ComputeEmbedding,
      ActionType.ComputeEmbeddingFailed,
      ActionType.ComputeEmbeddingComplete
    ),
    projection: createLoadingStatusReducer(
      ActionType.ComputeProjections,
      ActionType.ComputeProjectionFailed,
      ActionType.ComputeProjectionComplete
    ),
    savePermalink: createLoadingStatusReducer(
      ActionType.SavePermalink,
      ActionType.SavePermalinkFailed,
      ActionType.SavePermalinkComplete
    ),
    loadPermalink: createLoadingStatusReducer(
      ActionType.LoadPermalink,
      ActionType.LoadPermalinkFailed,
      ActionType.LoadPermalinkComplete
    ),
  }),
  alerts: reduceAlerts,
  permalink: reducePermalinkState,
  firebaseApp: reduceFirebaseApp,
});
