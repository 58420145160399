import Grid from '@material-ui/core/Grid';
import React, { FunctionComponent } from 'react';
import * as ReactRedux from 'react-redux';
import {
  useRouteMatch,
  match,
  useHistory,
  Switch,
  Route,
} from 'react-router-dom';

import * as actions from 'src/actions';
import FreeformTextControls from './FreeformTextControls';
import RedditPostsControls from './RedditPostsControls';
import RedditCommentsControls from './RedditCommentsControls';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import TwitterTimelineControls from './TwitterTimelineControls';
import { inDevMode } from 'src/util';
import { RootState } from 'src/types';
import RedditControls from './RedditControls';
import SavedNebulaControls from './SavedNebulaControls';
import _ from 'lodash';
import PremadeNebulaControls from './PremadeNebulaControls';

const BoundFreeformTextControls = ReactRedux.connect(
  (state: RootState) => ({
    freeText: '',
  }),
  {
    loadFreeformDataset: actions.loadFreeformDataset,
    displayAlert: actions.displayAlert,
  }
)(FreeformTextControls);

const BoundRedditControls = ReactRedux.connect(null, {
  fetchDataset: actions.fetchDataset,
})(RedditControls);

const BoundRedditPostsControls = ReactRedux.connect(null, {
  fetchDataset: actions.fetchDataset,
})(RedditPostsControls);

const BoundRedditCommentsControls = ReactRedux.connect(null, {
  fetchDataset: actions.fetchDataset,
})(RedditCommentsControls);

const BoundTwitterTimelineControls = ReactRedux.connect(null, {
  fetchDataset: actions.fetchDataset,
})(TwitterTimelineControls);

const BoundSavedNebulaControls = ReactRedux.connect(null, {
  loadPermalink: actions.loadPermalink,
})(SavedNebulaControls);

interface RoutedDatasetControlsProps {
  setPath: (path: string) => void;
}

const RoutedDatasetControls: FunctionComponent<RoutedDatasetControlsProps> = ({
  setPath,
}) => {
  return (
    <Switch>
      <Route path="/n/:shortname*">
        <PremadeNebulaControls setPath={setPath} />
      </Route>
      <Route path="/s/:saveId?">
        <BoundSavedNebulaControls setPath={setPath} />
      </Route>
      <Route path="/new-nebula">
        <BoundFreeformTextControls setPath={setPath} />
      </Route>
      <Route path="/twitter-timeline/:username?">
        <BoundTwitterTimelineControls setPath={setPath} />
      </Route>
      <Route path="/reddit-posts/:subreddit?">
        <BoundRedditPostsControls setPath={setPath} />
      </Route>
      <Route path="/reddit-comments/:subreddit?">
        <BoundRedditCommentsControls setPath={setPath} />
      </Route>
      <Route path="/subreddit/:subreddit?">
        <BoundRedditControls setPath={setPath} />
      </Route>
      <Route exact path="/">
        <PremadeNebulaControls setPath={setPath} />
      </Route>
    </Switch>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datasetControls: {
      marginTop: 4,
    },
    queryTypeSelectFormControl: {},
    loadButton: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      color: theme.palette.getContrastText(theme.palette.secondary.dark),
    },
  })
);

interface DatasetControlsProps {}

function DatasetControls(props: DatasetControlsProps) {
  const classes = useStyles();
  const history = useHistory();
  const routeMatch: match = useRouteMatch();
  const queryType = (routeMatch.params as any)['queryType'] || 'n';

  const setPath = (path: string) => {
    history.push(path);
  };
  const onChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    setPath(`/${e.target.value}`);
  };
  return (
    <Grid
      container
      className={classes.datasetControls}
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={1}
    >
      <Grid item className={classes.queryTypeSelectFormControl}>
        <FormControl variant="outlined">
          <InputLabel id="query-type-select-label">Data source</InputLabel>
          <Select
            id="query-type-select"
            labelId="query-type-select-label"
            label="Data source"
            value={queryType}
            onChange={onChange}
          >
            <MenuItem value="n">Premade Nebula</MenuItem>
            <MenuItem value="new-nebula">New Nebula</MenuItem>
            {inDevMode && <MenuItem value="subreddit">Reddit</MenuItem>}
            <MenuItem value="reddit-posts">Reddit posts</MenuItem>
            <MenuItem value="reddit-comments">Reddit comments</MenuItem>
            {inDevMode && (
              <MenuItem value="twitter-timeline">Twitter timeline</MenuItem>
            )}
            {(inDevMode || queryType === 's') && (
              <MenuItem value="s">Nebula ID</MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <RoutedDatasetControls setPath={setPath} />
      </Grid>
    </Grid>
  );
}

export default DatasetControls;
