import * as React from 'react';

import { LoadingState, LoadingStatus } from 'src/types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {},
  activeIcon: {
    color: theme.palette.secondary.main,
  },
  inactiveIcon: {
    color: theme.palette.grey[700],
  },
}));

const StatusTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontSize: '0.8em',
  },
}))(Tooltip);

// Quick and dirty, really only suited to this component's use of
// LoadingStates.
const mergeLoadingStates = (states: LoadingState[]) => {
  console.assert(states.length > 0);
  for (const state of states) {
    if (state.status === LoadingStatus.Loading) {
      return state;
    }
  }
  return states[0];
};

export interface StatusIconProps {
  iconComponent: React.Component;
  loadingState: LoadingState | LoadingState[];
  loadingNote: string;
}

export function statusIconOf(
  IconComponent: any
): FunctionComponent<StatusIconProps> {
  // TODO: Maybe possible to do this with functional components? Not covered in
  // HOC guide in React docs.
  return (props) => {
    const classes = useStyles();
    let state: LoadingState;
    if (Array.isArray(props.loadingState)) {
      state = mergeLoadingStates(props.loadingState);
    } else {
      state = props.loadingState;
    }
    return (
      <StatusTooltip
        title={props.loadingNote}
        open={state.status == LoadingStatus.Loading}
      >
        <IconComponent
          fontSize="large"
          className={
            classes.icon +
            ' ' +
            (state.status === LoadingStatus.Loading
              ? classes.activeIcon
              : classes.inactiveIcon)
          }
          color={
            state.status === LoadingStatus.Loading ? 'secondary' : 'disabled'
          }
        >
          {state.status === LoadingStatus.Loading ? 'Loading' : 'Ready'}
        </IconComponent>
      </StatusTooltip>
    );
  };
}
