import { FunctionComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import A from '../generic/A';
import SecondaryButton from '../generic/SecondaryButton';
import PrimaryButton from '../generic/PrimaryButton';

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaperRoot: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.text.primary,
  },
}));

interface PermalinkPrivacyWarningProps {
  onAccept: () => void;
  onClose: () => void;
}

const PermalinkPrivacyWarningDialog: FunctionComponent<PermalinkPrivacyWarningProps> = (
  props
) => {
  const classes = useStyles();
  const onCancelClicked = () => {
    props.onClose();
  };
  const onAcceptClicked = () => {
    props.onAccept();
    props.onClose();
  };
  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: { root: classes.dialogPaperRoot },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {'Create sharable link?'}
      </DialogTitle>
      <DialogContent>
        <Typography id="alert-dialog-description" component="div">
          <p>
            This will upload and create a link to the dataset currently loaded.
            Only people with the link can access it, but:
          </p>
          <ul>
            <li>
              You can only delete a dataset by emailing{' '}
              <A href="mailto:deletions@nebulate.ai">deletions@nebulate.ai</A>.
            </li>
            <li>
              It's technically possible for the operators of Nebulate to access
              it.
            </li>
            <li>
              There's a small chance that someone could guess the link even if
              you don't share it with them.
            </li>
          </ul>
          <p>
            Please email{' '}
            <A href="mailto:support@nebulate.ai">support@nebulate.ai</A> if this
            is preventing you from using Nebulate.
          </p>
        </Typography>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onCancelClicked}>Cancel</SecondaryButton>
        <PrimaryButton onClick={onAcceptClicked}>Create link</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default PermalinkPrivacyWarningDialog;
