import React, { FunctionComponent } from 'react';
import { RoamNode } from '@nebula/common';
import {
  CommonLabelContent,
  PointLabelContent,
  ContextLink,
} from './PointLabelContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  pageNameBracketText: {
    color: '#777',
  },
  pageNameText: {
    color: '#eee',
  },
}));

interface RoamPageNameProps {
  name: string;
}

const RoamPageName: FunctionComponent<RoamPageNameProps> = (props) => {
  const classes = useStyles();
  return (
    <span>
      <Typography className={classes.pageNameBracketText} component="span">
        [[
      </Typography>
      <Typography className={classes.pageNameText} component="span">
        {props.name}
      </Typography>
      <Typography className={classes.pageNameBracketText} component="span">
        ]]
      </Typography>
    </span>
  );
};

const RoamNodeLabelContent: PointLabelContent = (props) => {
  const item = props.point.item as RoamNode;
  return (
    <CommonLabelContent point={props.point}>
      {item.pageTitle && <RoamPageName name={item.pageTitle} />}
      {item.canonicalUrl && (
        <ContextLink href={item.canonicalUrl}>View on Roam</ContextLink>
      )}
    </CommonLabelContent>
  );
};

export default RoamNodeLabelContent;
