import React, { useState } from 'react';
import { Tweet } from '@nebula/common';
import * as ReactTwitter from 'react-twitter-widgets';
import { Theme, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import './twitter.css';
import { PointLabelContent } from './PointLabelContent';

const useStyles = makeStyles((theme: Theme) => {
  return {
    contentWrapper: {
      margin: '2px 0 2px 4px',
    },
    progressWrapper: {
      width: 100,
      margin: 'auto',
    },
  };
});

export const TweetLabelContent: PointLabelContent = (props) => {
  const classes = useStyles();
  const item = props.point.item as Tweet;

  const [loaded, setLoaded] = useState<boolean>(false);

  const onLoad = () => {
    setLoaded(true);
  };

  return (
    <div
      className={classes.contentWrapper}
      style={{
        width: loaded ? 350 : 40,
      }}
    >
      <ReactTwitter.Tweet
        tweetId={item.tweetId}
        onLoad={onLoad}
        options={{
          width: '350',
          theme: 'dark',
          cards: 'hidden',
          conversation: 'none',
          dnt: true,
        }}
      />
      {!loaded && (
        <div className={classes.progressWrapper}>
          <CircularProgress color="secondary" />
        </div>
      )}
    </div>
  );
};
