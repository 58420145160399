import { Location } from 'history';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { useRouteMatch, match, useLocation } from 'react-router-dom';

import { extractNumPoints } from 'src/util';
import DatasetControlForm from './DatasetControlForm';
import { NumPointsSelect } from './NumPointsSelect';

interface RedditCommentsControlsProps {
  fetchDataset: (path: string) => void;
  setPath: (path: string) => void;
}

const toPath = (subreddit: string, numPoints: number): string =>
  `/reddit-comments/${subreddit}?n=${numPoints}`;

export default (props: RedditCommentsControlsProps) => {
  const routeMatch: match = useRouteMatch();
  const subreddit = (routeMatch.params as any)['subreddit'] || null;
  const [subredditInput, setSubredditInput] = useState<string>(
    subreddit || 'worldnews'
  );

  const location: Location = useLocation();
  const numPoints: number = extractNumPoints(location);
  const [numPointsInput, setNumPointsInput] = useState<number>(
    numPoints || 128
  );

  useEffect(() => {
    console.log('effect triggered', subreddit, numPoints);
    if (subreddit && numPoints) {
      props.fetchDataset(toPath(subreddit, numPoints));
    }
  }, [routeMatch]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (subredditInput && numPointsInput) {
      props.setPath(toPath(subredditInput, numPointsInput));
    }
  };
  return (
    <DatasetControlForm onSubmit={onSubmit}>
      <TextField
        id="reddit-comments-subreddit-input"
        className="subreddit-input"
        label="Subreddit"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={subredditInput}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSubredditInput(e.target.value);
        }}
      />
      <NumPointsSelect
        numPoints={numPointsInput}
        setNumPoints={setNumPointsInput}
      />
    </DatasetControlForm>
  );
};
