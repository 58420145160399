import {
  Map,
  List,
  Record,
  Set,
  Seq,
  Collection,
  isIndexed,
  isKeyed,
} from 'immutable';

import WebpackWorker from 'worker-loader!*';
import { Item } from '@nebula/common';
import { Color } from '@material-ui/lab/Alert';
import { SplitMode, parseAsDatapoints } from './parsing/parse';

export type Key = string;

export type Table<V> = Map<Key, V>;

export type Embedding = List<number>;

export enum ProjectionType {
  Umap2D = 'UMAP_2D',
  Umap3D = 'UMAP_3D',
}

export type Projection = List<number>;

export class ProjectionParams extends Record({
  type: ProjectionType.Umap2D as ProjectionType,
}) {}

export class Datapoint extends Record({
  item: null as Item,
  embedding: null as Embedding,
  projections: Map() as Map<ProjectionType, Projection>,
}) {}

export enum LoadingStatus {
  Unknown = 'UNKNOWN',
  Loading = 'LOADING',
  Ready = 'READY',
  Broken = 'BROKEN',
}

export class LoadingState extends Record({
  status: LoadingStatus.Unknown,
  error: null as Error,
  message: null as string,
}) {}

export class FreeformDatasetInput extends Record({
  splitMode: null as SplitMode,
  text: null as string,
}) {
  _numPointsMemo: number = null;
  get numPoints() {
    this._numPointsMemo =
      this._numPointsMemo ||
      parseAsDatapoints(this.text, this.splitMode).length;
    return this._numPointsMemo;
  }
}

export class DatasetAnnotations extends Record({
  title: null as string,
  color: null as string,
}) {}

export class Dataset extends Record({
  path: null as string,
  directPoints: Map() as Table<Datapoint>,
  annotations: new DatasetAnnotations(),
  childDatasets: List<Dataset>(),
}) {
  // Convention: cheap, lazy operations get getters. Possibly expensive
  // operations get methods. Expensive means it iterates over points, not just
  // datasets.

  get datasets(): Seq.Indexed<Dataset> {
    return Seq.Indexed([this]).concat(
      this.childDatasets.flatMap((child) => child.datasets)
    );
  }

  get points(): Seq.Indexed<Datapoint> {
    return this.directPoints
      .valueSeq()
      .concat(...this.childDatasets.map((dataset) => dataset.points));
  }
  get keyedPoints(): Seq.Keyed<Key, Datapoint> {
    return this.directPoints
      .toSeq()
      .concat(...this.childDatasets.map((dataset) => dataset.keyedPoints));
  }
  pointsMap(): Map<Key, Datapoint> {
    return this.keyedPoints.toMap();
  }

  get numPoints(): number {
    return (
      this.directPoints.size +
      this.childDatasets.reduce(
        (acc: number, child: Dataset) => acc + child.numPoints,
        0
      )
    );
  }

  getPoint(key: Key): Datapoint {
    return (
      (this.directPoints.get(key, null) as Datapoint) ||
      this.childDatasets
        .find((dataset) => dataset.getPoint(key) != null)
        ?.getPoint(key) ||
      null
    );
  }

  setPoint(key: Key, point: Datapoint): Dataset {
    if (this.directPoints.has(key)) {
      return this.setIn(['directPoints', key], point);
    }
    for (let i = 0; i < this.childDatasets.size; i++) {
      const dataset = this.childDatasets.get(i);
      if (dataset.getPoint(key)) {
        return this.setIn(['childDatasets', i], dataset.setPoint(key, point));
      }
    }
    throw Error(`Couldn't find key ${key} in dataset.`);
  }

  private _setPointsDense(newPoints: Collection.Indexed<Datapoint>): Dataset {
    if (newPoints.count() !== this.numPoints) {
      throw Error(
        'newPoints must have the same number of points as the Dataset ' +
          'setPoints is being called on.'
      );
    }
    const newPointsList = List(newPoints);
    let resultDataset: Dataset = this.update(
      'directPoints',
      (dp: Table<Datapoint>) =>
        Map(dp.keySeq().zip(newPointsList.slice(0, this.directPoints.size)))
    );
    let currentPointIndex = this.directPoints.size;
    for (
      let datasetIndex = 0;
      datasetIndex < this.childDatasets.size;
      datasetIndex++
    ) {
      const child = this.childDatasets.get(datasetIndex);
      const numPoints = child.numPoints;
      resultDataset = resultDataset.setIn(
        ['childDatasets', datasetIndex],
        child._setPointsDense(
          newPointsList.slice(currentPointIndex, currentPointIndex + numPoints)
        )
      );
      currentPointIndex += numPoints;
    }
    return resultDataset;
  }

  private _setPointsSparse(
    newPoints: Collection.Keyed<Key, Datapoint>
  ): Dataset {
    let resultDataset: Dataset = this;
    for (const [key, point] of newPoints.entrySeq()) {
      resultDataset = resultDataset.setPoint(key, point);
    }
    return resultDataset;
  }

  setPoints(
    newPoints:
      | Collection.Indexed<Datapoint>
      | Array<Datapoint>
      | Collection.Keyed<Key, Datapoint>
      | { [key: string]: Datapoint }
  ): Dataset {
    if (isIndexed(newPoints)) {
      return this._setPointsDense(newPoints);
    } else if (Array.isArray(newPoints)) {
      return this._setPointsDense(Seq.Indexed(newPoints));
    } else if (isKeyed(newPoints)) {
      return this._setPointsSparse(newPoints);
    } else if (newPoints != null && typeof newPoints === 'object') {
      return this._setPointsSparse(Seq.Keyed(newPoints));
    }
  }

  _setInPointsDense(
    path: string[],
    newValues: Collection.Indexed<any>
  ): Dataset {
    // TODO: Use withMutations to make this more efficient?
    const flatUpdatedPoints = this.points
      .zip(newValues)
      .map(([p, newValue]) => p.setIn(path, newValue));
    return this._setPointsDense(flatUpdatedPoints);
  }

  _setInPointsSparse(
    path: string[],
    newValues: Collection.Keyed<Key, any>
  ): Dataset {
    // TODO: Use withMutations to make this more efficient?
    const flatUpdatedPoints = newValues.map((value, key) =>
      this.getPoint(key).setIn(path, value)
    );
    return this._setPointsSparse(flatUpdatedPoints);
  }

  setInPoints(
    path: string[],
    newValues:
      | Collection.Indexed<any>
      | Array<any>
      | Collection.Keyed<Key, any>
      | { [key: string]: any }
  ) {
    if (isIndexed(newValues)) {
      return this._setInPointsDense(path, newValues);
    } else if (Array.isArray(newValues)) {
      return this._setInPointsDense(path, Seq.Indexed(newValues));
    } else if (isKeyed(newValues)) {
      return this._setInPointsSparse(path, newValues);
    } else if (newValues != null && typeof newValues === 'object') {
      return this._setInPointsSparse(path, Seq.Keyed(newValues));
    }
  }

  mapPoints(
    f: (point: Datapoint, key: Key, index: number) => Datapoint
  ): Dataset {
    const flatMapped = this.keyedPoints
      // To a Seq.Indexed of [key, value]
      .entrySeq()
      // To a Seq.Indexed of [index, [key, value]]
      .entrySeq()
      .map(([index, [key, point]]) => f(point, key, index));
    return this.setPoints(flatMapped);
  }
}

export enum DisplayStatus {
  Default = 'DEFAULT',
  Ajar = 'AJAR',
  Open = 'OPEN',
}

export class ProjectionView extends Record({
  hoveredPoint: null as Key,
  ajarPoints: Set() as Set<Key>,
  openPoints: Set() as Set<Key>,
}) {}

export class DisplayPoint extends Record({
  key: null as string,
  position: null as THREE.Vector3,
  item: null as Item,
  selected: false,
  color: null as THREE.Color,
}) {}

export class PermalinkState extends Record({
  url: null as string,
  savedState: null as SavableState,
}) {}

////

export class UserAlert extends Record({
  id: null as string,
  timestamp: null as number,
  title: null as string,
  description: null as string,
  severity: null as Color,
}) {}

////

export interface RootState {
  dataset: Dataset;
  embedWorker: WebpackWorker;
  projectionParams: ProjectionParams;
  loadingState: {
    dataset: LoadingState;
    embedding: LoadingState;
    projection: LoadingState;
    savePermalink: LoadingState;
    loadPermalink: LoadingState;
  };
  alerts: Map<string, UserAlert>;
  projectionView: ProjectionView;
  permalink: PermalinkState;
  firebaseApp: firebase.app.App;
}

export class SavableState extends Record({
  serializationVersion: null as string,
  dataset: null as Dataset,
  projectionParams: null as ProjectionParams,
  projectionView: null as ProjectionView,
}) {}
