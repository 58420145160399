import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/storage';
import { eventChannel, Saga } from 'redux-saga';
import {
  call,
  cancelled,
  fork,
  put,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
  delay,
  cancel,
} from 'redux-saga/effects';

import {
  ActionType,
  LoadFreeTextDatasetComplete,
  LoadFreeTextDatasetFailed,
  LoadFreeTextDataset,
  FetchDatasetComplete,
  Action,
  EmbedWorkerStart,
  EmbedWorkerEmbed,
  EmbedWorkerEmbedComplete,
  EmbedWorkerEmbedFailed,
  ComputeEmbeddingComplete,
  ComputeProjectionFailed,
  ComputeProjectionComplete,
  ComputeEmbedding,
  ComputeProjections,
  ComputeEmbeddingFailed,
  FetchDatasetFailed,
  FetchDataset,
  FirebaseInitComplete,
  SavePermalink,
  SavePermalinkComplete,
  SavePermalinkFailed,
  LoadPermalink,
  LoadPermalinkFailed,
  LoadPermalinkComplete,
  DisplayAlert,
  DisplayAlertEnd,
} from './action-types';
import { parseAsDatapoints, SplitMode } from './parsing/parse';
import {
  Datapoint,
  Dataset,
  RootState,
  ProjectionParams,
  SavableState,
  ProjectionType,
  UserAlert,
  FreeformDatasetInput,
} from './types';
import {
  keyTable,
  key,
  PushIdGen,
  inDevMode,
  permalinkIdToLink,
  offscreenCanvasSupported,
  serializeState,
  deserializeState,
  primaryText,
} from './util';
import EmbedWorker from 'worker-loader!src/embedding/embed.worker';
import { projectDataset } from './embedding/project';
import { savableStateNoEmbeddings } from './selectors';
import { AnyAction } from 'redux';
import { fetchDatapointsFromApi } from './data-api';
import _ from 'lodash';
import { firebaseConfig } from './firebase';
import {
  SAVED_NEBULA_ID_PREFIX,
  CURRENT_STATE_SERIALIZATION_VERSION,
  MAX_POINTS_EMBED,
} from './constants';
import { displayAlert } from './actions';
import { List } from 'immutable';

// Design principle: one takeLatest/takeEvery for each unit of work we
// need to be able to cancel all at once. Reasons something might need to be
// canceled:
// - Functional reasons, e.g. earlier action won't be relevant after later
//   actions take effect, or to ensure actions are taken and outputs written in
//   a particular order.
// - Users might want to cancel something that interacts with outside systems.

// There should be a sagas that corresponds to each of these logical actions,
// but they can be made up of multiple sagas via call, fork, etc.

// Actions should be used as the catalyzing events representing some external
// stimulus and a log that can be reduced over to compute the sequence of store
// states.

const dispatch = (actionSagaMap: ActionSagaMap, ...args: any[]) =>
  function* (action: Action) {
    const saga = _.get(actionSagaMap, action.type, null);
    console.assert(saga);
    const actionResult = yield saga(action, ...args);
    return { [action.type]: actionResult };
  };

type ActionSagaMap = {
  [actionType in ActionType]?: Saga;
};

function* parseFreeformAsDataset(datasetInputs: List<FreeformDatasetInput>) {
  try {
    let dataset = new Dataset();
    for (const input of datasetInputs) {
      dataset = dataset.update('childDatasets', (childDatasets) =>
        childDatasets.push(
          new Dataset({
            directPoints: keyTable(
              parseAsDatapoints(input.text, input.splitMode).slice(
                0,
                MAX_POINTS_EMBED
              )
            ),
          })
        )
      );
    }
    yield put<LoadFreeTextDatasetComplete>({
      type: ActionType.LoadFreeTextDatasetComplete,
      dataset: dataset,
    });
    return dataset;
  } catch (error) {
    console.error(error);
    yield put<LoadFreeTextDatasetFailed>({
      type: ActionType.LoadFreeTextDatasetFailed,
      error: error,
    });
  } finally {
    if (yield cancelled()) {
      yield put<LoadFreeTextDatasetFailed>({
        type: ActionType.LoadFreeTextDatasetFailed,
        error: Error('Parsing freeform text as dataset interrupted'),
      });
    }
  }
}

function* fetchDatasetFromApi(path: string) {
  try {
    const datapoints: Datapoint[] = yield fetchDatapointsFromApi(path);
    const dataset = new Dataset({
      path: path,
      directPoints: keyTable(datapoints),
    });
    yield put<FetchDatasetComplete>({
      type: ActionType.FetchDatasetComplete,
      dataset: dataset,
    });
    return dataset;
  } catch (error) {
    console.error(error);
    yield put<FetchDatasetFailed>({
      type: ActionType.FetchDatasetFailed,
      error: error,
    });
  } finally {
    if (yield cancelled()) {
      yield put<FetchDatasetFailed>({
        type: ActionType.FetchDatasetFailed,
        error: Error('Fetching dataset from API interrupted'),
      });
    }
  }
}

function* warnLongEmbeddingTime(delayMs: number) {
  yield delay(delayMs);
  yield put<DisplayAlert>(
    displayAlert(
      new UserAlert({
        title: 'Running model taking a long time',
        description:
          'Running machine learning models may be slow on some hardware.',
        severity: 'warning',
      })
    )
  );
}

function* computeEmbedding(dataset: Dataset) {
  yield put({
    type: ActionType.ComputeEmbedding,
    dataset: dataset,
  } as ComputeEmbedding);
  if (!offscreenCanvasSupported()) {
    yield put<DisplayAlert>(
      displayAlert(
        new UserAlert({
          title: 'Browser might not be supported',
          description:
            "Running a machine learning model requires a feature your browser doesn't have.",
          severity: 'warning',
        })
      )
    );
  }
  const id = key();
  try {
    const warnTask = yield fork(
      warnLongEmbeddingTime,
      Math.max(dataset.numPoints * 150, 15000)
    );
    yield put<EmbedWorkerEmbed>({
      type: ActionType.EmbedWorkerEmbed,
      id: id,
      text: dataset.points.map((p) => primaryText(p.item)).toArray(),
    });
    let embedWorkerResponse: EmbedWorkerEmbedComplete | EmbedWorkerEmbedFailed;
    while (true) {
      embedWorkerResponse = yield take([
        ActionType.EmbedWorkerEmbedComplete,
        ActionType.EmbedWorkerEmbedFailed,
      ]);
      if (embedWorkerResponse.id === id) break;
    }
    yield cancel(warnTask);
    if (embedWorkerResponse.type === ActionType.EmbedWorkerEmbedComplete) {
      const embeddedDataset = dataset.setInPoints(
        ['embedding'],
        embedWorkerResponse.embeddings.map((e) => List(e))
      );
      yield put<ComputeEmbeddingComplete>({
        type: ActionType.ComputeEmbeddingComplete,
        dataset: embeddedDataset,
      });
      return embeddedDataset;
    } else if (embedWorkerResponse.type === ActionType.EmbedWorkerEmbedFailed) {
      yield put<ComputeEmbeddingFailed>({
        type: ActionType.ComputeEmbeddingFailed,
        error: Error(embedWorkerResponse.error),
      });
    }
  } finally {
    if (yield cancelled()) {
      yield put<ComputeEmbeddingFailed>({
        type: ActionType.ComputeEmbeddingFailed,
        error: Error('Embedding interrupted'),
      });
    }
  }
}

function* computeProjection(type: ProjectionType, dataset: Dataset) {
  const projections: number[][] = yield projectDataset(
    type,
    dataset.points.map((p) => p.embedding.toArray()).toArray()
  );

  yield put<ComputeProjectionComplete>({
    type: ActionType.ComputeProjectionComplete,
    projectionType: type,
    projections: List(projections.map((p) => List(p))),
  });
}

function* computeProjections(types: ProjectionType[], dataset: Dataset) {
  yield put<ComputeProjections>({
    type: ActionType.ComputeProjections,
    projectionTypes: types,
  });
  try {
    const state: RootState = yield select();
    const projectionParams: ProjectionParams = state.projectionParams;
    const orderedTypes = _.sortBy(
      types,
      (type: ProjectionType) => projectionParams.type !== type
    );
    for (const type of orderedTypes) {
      yield call(computeProjection, type, dataset);
    }
    // Hack - transitioning from an API that updates and threads the datasets to
    // one that emits actions with just the incremental results and calls the
    // store if it needs the accumulated result.
    // TODO: Apply a similar change to embedding update.
    return (yield select()).dataset;
  } catch (error) {
    console.error(error);
    yield put<ComputeProjectionFailed>({
      type: ActionType.ComputeProjectionFailed,
      error: error,
    });
  } finally {
    if (yield cancelled()) {
      yield put<ComputeProjectionFailed>({
        type: ActionType.ComputeProjectionFailed,
        error: Error('Projection interrupted'),
      });
    }
  }
}

function* loadFreeTextDatasetFlow(action: LoadFreeTextDataset) {
  let dataset: Dataset = yield parseFreeformAsDataset(action.datasetInputs);
  if (!dataset) return;
  dataset = yield computeEmbedding(dataset);
  if (!dataset) return;
  dataset = yield computeProjections(
    [ProjectionType.Umap2D, ProjectionType.Umap3D],
    dataset
  );
}

function* fetchDatasetFromApiFlow(action: FetchDataset) {
  let dataset: Dataset = yield fetchDatasetFromApi(action.path);
  if (!dataset) return;
  dataset = yield computeEmbedding(dataset);
  if (!dataset) return;
  dataset = yield computeProjections(
    [ProjectionType.Umap2D, ProjectionType.Umap3D],
    dataset
  );
}

const datasetActionSagas: ActionSagaMap = {
  LOAD_FREE_TEXT_DATASET: loadFreeTextDatasetFlow,
  FETCH_DATASET: fetchDatasetFromApiFlow,
};

// TODO: It might make sense to queue these actions so they happen one after
// another rather than canceling incomplete actions.

function* watchDatasetActions() {
  yield takeLatest(_.keys(datasetActionSagas), dispatch(datasetActionSagas));
}

function* startEmbedWorker() {
  const worker = new EmbedWorker();
  yield put<EmbedWorkerStart>({
    type: ActionType.EmbedWorkerStart,
    worker: worker,
  });
  return worker;
}

function createEmbedWorkerChannel(worker: EmbedWorker) {
  return eventChannel((emit: (message: any) => void) => {
    worker.onmessage = (e: MessageEvent) => {
      emit(e.data);
    };
    const unsubscribe = () => {
      worker.onmessage = null;
      worker.onerror = null;
      worker.onmessageerror = null;
    };
    return unsubscribe;
  });
}

function* watchEmbedWorkerOutput(worker: EmbedWorker) {
  const workerChannel = yield call(createEmbedWorkerChannel, worker);
  while (true) {
    const actionFromWorker = yield take(workerChannel);
    yield put(actionFromWorker);
  }
}

function* watchEmbedWorkerInput(worker: EmbedWorker) {
  yield takeEvery(ActionType.EmbedWorkerEmbed, function* (action: AnyAction) {
    worker.postMessage(action);
  });
}

function* watchEmbedWorker() {
  const worker: EmbedWorker = yield call(startEmbedWorker);
  yield fork(watchEmbedWorkerOutput, worker);
  yield fork(watchEmbedWorkerInput, worker);
}

function* initFirebase() {
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  if (!inDevMode) {
    firebaseApp.analytics();
  }
  yield put<FirebaseInitComplete>({
    type: ActionType.FirebaseInitComplete,
    firebaseApp,
  });
  return firebaseApp;
}

function* savePermalink(action: SavePermalink, firebase: firebase.app.App) {
  try {
    const state: SavableState = yield select(savableStateNoEmbeddings);
    const stateSaveRef = firebase
      .storage()
      .ref()
      .child(SAVED_NEBULA_ID_PREFIX)
      .child(PushIdGen.generate());
    const serializedState: string = serializeState(state);
    const result = yield stateSaveRef.putString(serializedState, 'raw', {
      contentType: 'application/json',
    });
    yield put<SavePermalinkComplete>({
      type: ActionType.SavePermalinkComplete,
      url: permalinkIdToLink(result.ref.fullPath),
      savedState: state,
    });
  } catch (error) {
    console.error(error);
    yield put<SavePermalinkFailed>({
      type: ActionType.SavePermalinkFailed,
      error: error,
    });
  } finally {
    if (yield cancelled()) {
      yield put<SavePermalinkFailed>({
        type: ActionType.SavePermalinkFailed,
        error: Error('Saving permalink interrupted'),
      });
    }
  }
}

function* loadPermalink(action: LoadPermalink, firebase: firebase.app.App) {
  try {
    const ref = firebase.storage().ref().child(action.id);
    const url = yield ref.getDownloadURL();
    const response: Response = yield fetch(url);
    const body = yield response.text();
    const state: SavableState = deserializeState(body);
    console.assert(
      state.serializationVersion === CURRENT_STATE_SERIALIZATION_VERSION
    );
    yield put<LoadPermalinkComplete>({
      type: ActionType.LoadPermalinkComplete,
      id: action.id,
      savedState: state,
    });
  } catch (error) {
    console.error(error);
    yield put<LoadPermalinkFailed>({
      type: ActionType.LoadPermalinkFailed,
      error,
    });
  } finally {
    if (yield cancelled()) {
      yield put<LoadPermalinkFailed>({
        type: ActionType.LoadPermalinkFailed,
        error: Error('Loading permalink interrupted'),
      });
    }
  }
}

const firebaseActionSagas = {
  [ActionType.SavePermalink]: savePermalink,
  [ActionType.LoadPermalink]: loadPermalink,
};

function* watchFirebaseActions() {
  const firebaseApp = yield call(initFirebase);
  yield takeLatest(
    _.keys(firebaseActionSagas),
    dispatch(firebaseActionSagas, firebaseApp)
  );
}

function* eventuallyEndAlert(action: DisplayAlert) {
  yield delay(action.durationMs);
  yield put<DisplayAlertEnd>({
    type: ActionType.DisplayAlertEnd,
    id: action.alert.id,
  });
}

function* watchAlertActions() {
  yield takeEvery(ActionType.DisplayAlert, eventuallyEndAlert);
}

export default function* rootSaga() {
  const children: any[] = [
    watchFirebaseActions,
    watchDatasetActions,
    watchEmbedWorker,
    watchAlertActions,
  ];
  for (const child of children) {
    yield spawn(child);
  }
}
