import * as redux from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import { reduceRoot } from './reducers';
import DevTools from './components/dev-tools/DevTools';
import rootSaga from './sagas';

export function createNebulaStore() {
  const sagaMiddleware = createSagaMiddleware();
  const enhancer = redux.compose(
    // Middleware you want to use in development:
    redux.applyMiddleware(thunk),
    redux.applyMiddleware(sagaMiddleware),
    // Required! Enable Redux DevTools with the monitors you chose
    DevTools.instrument()
  );

  const store = redux.createStore(reduceRoot, enhancer);
  sagaMiddleware.run(rootSaga);

  return store;
}
