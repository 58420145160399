import { Item } from '@nebula/common';
import { Datapoint } from 'src/types';
import { inDevMode } from 'src/util';

type ApiResponse = Array<Item>;

const API_URL_PREFIX: string = inDevMode
  ? 'http://localhost:5000/nebula-0/us-central1/api'
  : 'https://us-central1-nebula-0.cloudfunctions.net/api';

export const fetchDatapointsFromApi = async (
  path: string
): Promise<Array<Datapoint>> => {
  const url = API_URL_PREFIX + path;
  const response = await fetch(url);
  return ((await response.json()) as ApiResponse).map(
    (item: Item) =>
      new Datapoint({
        item: item,
      })
  );
};
