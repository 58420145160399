import React from 'react';
import { RedditComment } from '@nebula/common';
import {
  CommonLabelContent,
  PointLabelContent,
  ContextLink,
} from './PointLabelContent';

const RedditCommentLabelContent: PointLabelContent = (props) => {
  const item = props.point.item as RedditComment;
  return (
    <CommonLabelContent point={props.point}>
      <ContextLink href={item.canonicalUrl}>View on Reddit</ContextLink>
    </CommonLabelContent>
  );
};

export default RedditCommentLabelContent;
