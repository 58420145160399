import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.secondary.light,
  },
}));

interface AProps {
  href: string;
  className?: string;
}

// Anchor-like component
const A: FunctionComponent<AProps> = (props) => {
  const classes = useStyles();
  return (
    <Link
      className={classes.link + ' ' + props.className}
      target="_blank"
      rel="noopener"
      {...props}
    ></Link>
  );
};

export default A;
