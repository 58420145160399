import * as React from 'react';
import * as ReactRedux from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme, makeStyles, Theme } from '@material-ui/core/styles';
import BlurOnRoundedIcon from '@material-ui/icons/BlurOnRounded';
import FlashOnRoundedIcon from '@material-ui/icons/FlashOnRounded';
import TextRotateVerticalRoundedIcon from '@material-ui/icons/TextRotateVerticalRounded';
import { ThemeProvider } from '@material-ui/styles';

import * as actions from 'src/actions';
import { RootState } from 'src/types';
import { inDevMode } from 'src/util';

import './app.css';
import discordLogoSvgUrl from 'src/assets/img/Discord-Logo-Color.svg';
import DatasetControls from './dataset-controls/DatasetControls';
import DevTools from './dev-tools/DevTools';
import ProjectionCanvas from './embedding/ProjectionCanvas';
import { statusIconOf } from './status-icon/StatusIcon';
import { InfoButton } from './info-button/InfoButton';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import PermalinkControls from './permalink/PermalinkControls';
import ProjectionControls from './projection-controls/ProjectionControls';
import AlertDisplay from './alert-display/AlertDisplay';

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#3DA7FD',
    },
    secondary: {
      main: '#ff2e63',
    },
  },
  zIndex: {
    mobileStepper: 10000,
    speedDial: 10500,
    appBar: 11000,
    drawer: 12000,
    modal: 13000,
    snackbar: 14000,
    tooltip: 15000,
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  statusIconRow: {
    marginTop: 4,
  },
  gridSpacer: {
    flex: 1,
  },
  imageIcon: {
    height: '115%',
  },
  iconRoot: {
    textAlign: 'center',
  },
  appbar: {
    backgroundColor: '#121212',
    paddingLeft: 10,
  },
}));

const DatasetStatusIcon: any = ReactRedux.connect(
  (state: RootState) => ({
    loadingState: [
      state.loadingState.dataset,
      state.loadingState.loadPermalink,
    ],
  }),
  null
)(statusIconOf(TextRotateVerticalRoundedIcon));

const EmbeddingStatusIcon: any = ReactRedux.connect(
  (state: RootState) => ({
    loadingState: state.loadingState.embedding,
  }),
  null
)(statusIconOf(FlashOnRoundedIcon));

const ProjectionStatusIcon: any = ReactRedux.connect(
  (state: RootState) => ({
    loadingState: state.loadingState.projection,
  }),
  null
)(statusIconOf(BlurOnRoundedIcon));

const BoundDatasetControls = ReactRedux.connect(null, {
  onQueryChanged: (query: string) => actions.fetchDataset(query),
})(DatasetControls);

const BoundProjectionControls = ReactRedux.connect(
  (state: RootState) => ({
    params: state.projectionParams,
  }),
  {
    setParams: actions.setProjectionParams,
  }
)(ProjectionControls);

export interface AppProps {}

const App = (props: AppProps) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <ThemeProvider theme={darkTheme}>
        <div id="app-content">
          <AppBar className={classes.appbar} position="static" elevation={1}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <BoundDatasetControls></BoundDatasetControls>
              </Grid>
              <Grid item className={classes.statusIconRow}>
                <DatasetStatusIcon loadingNote={'Loading dataset'} />
                <EmbeddingStatusIcon loadingNote={'Running model'} />
                <ProjectionStatusIcon loadingNote={'Projecting'} />
              </Grid>
              <Grid item>
                <BoundProjectionControls />
              </Grid>
              <Grid item>
                <PermalinkControls />
              </Grid>
              <Grid item className={classes.gridSpacer} />
              <Grid item>
                <IconButton
                  href="https://discord.gg/KEcSEAh"
                  target="_blank"
                  rel="noopener"
                >
                  <Icon classes={{ root: classes.iconRoot }}>
                    <img
                      className={classes.imageIcon}
                      src={discordLogoSvgUrl}
                    />
                  </Icon>
                </IconButton>
              </Grid>
              <Grid item>
                <InfoButton />
              </Grid>
            </Grid>
          </AppBar>
          <ProjectionCanvas />
        </div>
      </ThemeProvider>
      <AlertDisplay />
      {inDevMode ? <DevTools /> : null}
    </React.Fragment>
  );
};

export default App;
