import * as React from 'react';
import { Html } from 'drei';
import * as THREE from 'three';
import { List } from 'immutable';

import { DisplayPoint, DisplayStatus, ProjectionView } from 'src/types';

import PointLabel from './PointLabel';
import { displayStatusForPoint } from 'src/util';

export interface PointLabelsProps {
  threePoints: THREE.Points;
  points: List<DisplayPoint>;
  onPointClosed: (key: string) => void;
  cameraPosition: THREE.Vector3;
  projectionView: ProjectionView;
  labelContainerRef: React.MutableRefObject<HTMLDivElement>;
}

function PointLabels(props: PointLabelsProps) {
  if (props.threePoints == null) {
    return null;
  }
  const displayStatuses = props.points.map((p) =>
    displayStatusForPoint(p.key, props.projectionView)
  );
  return (
    <group>
      {props.points
        .zip(displayStatuses)
        .filter(
          ([pt, status]: [DisplayPoint, DisplayStatus]) =>
            status !== DisplayStatus.Default
        )
        .map(([pt, status]: [DisplayPoint, DisplayStatus]) => {
          return (
            <Html
              key={pt.key}
              position={pt.position}
              style={{ position: 'static' }}
              zIndexRange={[3999, 3000]}
              portal={props.labelContainerRef}
            >
              {
                <PointLabel
                  point={pt}
                  displayStatus={status}
                  onPointClosed={() => props.onPointClosed(pt.key)}
                ></PointLabel>
              }
            </Html>
          );
        })}
    </group>
  );
}

export default PointLabels;
