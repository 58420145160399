import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import * as React from 'react';

interface NumPointsSelect {
  numPoints: number;
  setNumPoints: (numPoints: number) => void;
}

export const NumPointsSelect = (props: NumPointsSelect) => {
  return (
    <FormControl variant="outlined">
      <InputLabel id="num-points-select-label">Points</InputLabel>
      <Select
        id="num-points-select"
        labelId="num-points-select-label"
        label="Points"
        value={props.numPoints}
        onChange={(event) => {
          props.setNumPoints(event.target.value as number);
        }}
      >
        <MenuItem value={64}>64</MenuItem>
        <MenuItem value={128}>128</MenuItem>
        <MenuItem value={256}>256</MenuItem>
        <MenuItem value={512}>512</MenuItem>
      </Select>
    </FormControl>
  );
};
