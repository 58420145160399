import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';

import { createNebulaStore } from 'src/store';
import { RootState } from 'src/types';

import App from './App';

interface RootComponentState {
  store: Redux.Store<RootState, Redux.AnyAction>;
}

class Root extends React.Component<any, RootComponentState> {
  constructor(props: any) {
    super(props);
    this.state = {
      store: createNebulaStore(),
    };
  }

  render() {
    return (
      <ReactRedux.Provider store={this.state.store}>
        <BrowserRouter>
          <Route path="/:queryType?">
            <App />
          </Route>
        </BrowserRouter>
      </ReactRedux.Provider>
    );
  }
}

export default Root;
