import _ from 'lodash';

export const CURRENT_STATE_SERIALIZATION_VERSION = 'v1';
export const SAVED_NEBULA_ID_PREFIX = 's/';

export const HERO_DATASETS = [
  {
    shortname: 'twitter/hour/latest',
    title: 'Latest tweets',
    id: 'n/twitter/hour/latest',
  },
  {
    shortname: 'trump-biden-tweets',
    title: 'Trump & Biden tweets',
    id: 's/-MG7Dk599R0OiGHHw0HS',
  },
  {
    shortname: 'declarations',
    title: 'Declarations',
    id: 's/-MEncBLVo7MB6aE5xsFi',
  },
  {
    shortname: 'inaugurals',
    title: 'Inaugural addresses',
    id: 's/-MEtXYRMXzF3drasJyQM',
  },
];
export const SHORTNAME_TO_DATASET = _.keyBy(HERO_DATASETS, 'shortname');

export const LOCAL_STORAGE_KEY_INFO_PANEL_CLOSED = 'nebula-info-panel-closed';

export const MAX_POINTS_DISPLAY = 20000;
export const MAX_POINTS_EMBED = 1500;
