import Grid from '@material-ui/core/Grid';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import React, { FunctionComponent } from 'react';

import { ItemType } from '@nebula/common';
import { DisplayPoint, DisplayStatus } from 'src/types';
import { primaryText } from 'src/util';

import { TweetLabelContent } from './TweetLabelContent';
import RedditPostLabelContent from './RedditPostLabelContent';
import RedditCommentLabelContent from './RedditCommentLabelContent';
import { CommonLabelContent } from './PointLabelContent';
import RoamNodeLabelContent from './RoamNodeLabelContent';

const useStyles = makeStyles((theme: Theme) => {
  const flagstaffOffset = 20;
  return {
    labelWrapper: {},
    labelFlag: {
      borderTop: '1px solid rgba(255, 255, 255, 70%)',
      paddingLeft: flagstaffOffset,
    },
    labelOpen: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    labelAjar: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    labelClosed: {
      display: 'none',
    },
    dot: {
      position: 'absolute',
      borderRadius: '50%',
      display: 'inline-block',
      boxSizing: 'border-box',
    },
    solidDot: {
      backgroundColor: 'rgba(255, 255, 255, 85%)',
    },
    emptyDot: {
      border: '1.5px solid rgba(255, 255, 255, 85%)',
    },
    openLabelContent: {
      flexGrow: 1,
    },
    toolPanelColumn: {
      margin: '2px 0 0 0',
      padding: '0 3px 0 2px',
    },
    toolPanelRow: {},
    closeButton: {
      padding: 0,
      zIndex: 1900,
    },
    pointPreview: {
      padding: 8,
      maxHeight: 60,
    },
    previewText: {
      color: theme.palette.getContrastText('#000'),
      maxWidth: 300,
      overflow: 'hidden',
      overflowWrap: 'break-word',
      lineHeight: '1rem',
      fontSize: '1rem',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
    },
    closeIcon: {
      color: theme.palette.secondary.light,
    },
  };
});

function labelContentComponent(point: DisplayPoint): JSX.Element {
  const itemType = point.item.type;
  if (itemType === ItemType.Tweet) {
    return <TweetLabelContent point={point} />;
  } else if (itemType === ItemType.RedditPost) {
    return <RedditPostLabelContent point={point} />;
  } else if (itemType === ItemType.RedditComment) {
    return <RedditCommentLabelContent point={point} />;
  } else if (itemType === ItemType.RoamNode) {
    return <RoamNodeLabelContent point={point} />;
  } else {
    return <CommonLabelContent point={point} />;
  }
}

const CloseButton: FunctionComponent<IconButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <IconButton className={classes.closeButton} {...props}>
      <CancelRoundedIcon className={classes.closeIcon} fontSize="small" />
    </IconButton>
  );
};

interface ToolPanelProps {
  onClose: () => void;
}

const ToolPanel: FunctionComponent<ToolPanelProps> = (props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      className={classes.toolPanelColumn}
    >
      <Grid item className={classes.toolPanelRow}>
        <CloseButton onClick={props.onClose} />
      </Grid>
    </Grid>
  );
};

interface OpenLabelProps {
  point: DisplayPoint;
  onPointClosed: () => void;
}

const OpenLabel: FunctionComponent<OpenLabelProps> = (props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.labelOpen} style={{ zIndex: 1800 }} square>
      <Grid container className={classes.openLabelContent} direction="row">
        <Grid item>{labelContentComponent(props.point)}</Grid>
        <Grid item>
          <ToolPanel onClose={props.onPointClosed} />
        </Grid>
      </Grid>
    </Paper>
  );
};

interface PointPreviewProps {
  point: DisplayPoint;
}

const PointPreview: FunctionComponent<PointPreviewProps> = (props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.labelAjar} style={{ zIndex: 1100 }} square>
      <div className={classes.pointPreview}>
        <Typography className={classes.previewText}>
          {primaryText(props.point.item)}
        </Typography>
      </div>
    </Paper>
  );
};

export interface PointLabelProps {
  point: DisplayPoint;
  displayStatus: DisplayStatus;
  onPointClosed: () => void;
}

const PointLabel: FunctionComponent<PointLabelProps> = (props) => {
  const displayStatus = props.displayStatus;
  const classes = useStyles();
  const displayStatusZIndices = {
    [DisplayStatus.Default]: null as number,
    [DisplayStatus.Ajar]: 1100,
    [DisplayStatus.Open]: 1800,
  };
  const zIndex = displayStatusZIndices[displayStatus];
  const radius = 5;
  let labelComponent = null;
  if (props.displayStatus === DisplayStatus.Ajar) {
    labelComponent = <PointPreview point={props.point} />;
  } else if (props.displayStatus === DisplayStatus.Open) {
    labelComponent = (
      <OpenLabel
        point={props.point}
        onPointClosed={props.onPointClosed}
        children={props.children}
      />
    );
  }
  return (
    <div className={classes.labelWrapper}>
      <div
        className={`${classes.dot} ${
          displayStatus === DisplayStatus.Ajar
            ? classes.emptyDot
            : classes.solidDot
        }`}
        style={{
          height: radius * 2,
          width: radius * 2,
          left: -radius,
          top: -radius,
          zIndex: zIndex,
        }}
      />
      <div
        className={classes.labelFlag}
        style={{
          marginLeft: radius,
          zIndex: zIndex,
          pointerEvents: displayStatus === DisplayStatus.Open ? 'auto' : 'none',
        }}
      >
        {labelComponent}
      </div>
    </div>
  );
};

export default PointLabel;
