import {
  ProjectionParams,
  ProjectionType,
  DatasetAnnotations,
  UserAlert,
  FreeformDatasetInput,
} from 'src/types';

import {
  ActionType,
  FetchDataset,
  ComputeProjections,
  LoadFreeTextDataset,
  SetProjectionParams,
  SavePermalink,
  LoadPermalink,
  SetDatasetAnnotations,
  DisplayAlert,
} from './action-types';
import { PushIdGen, recordToObjNonNull } from './util';
import { List } from 'immutable';

export function loadFreeformDataset(
  datasetInputs: List<FreeformDatasetInput>
): LoadFreeTextDataset {
  return {
    type: ActionType.LoadFreeTextDataset,
    datasetInputs,
  };
}

export function fetchDataset(path: string) {
  return {
    type: ActionType.FetchDataset,
    path,
  } as FetchDataset;
}

export function setProjectionParams(params: ProjectionParams) {
  return {
    type: ActionType.SetProjectionParams,
    params: params,
  } as SetProjectionParams;
}

export function computeProjection(
  projectionTypes: ProjectionType[]
): ComputeProjections {
  return {
    type: ActionType.ComputeProjections,
    projectionTypes,
  } as ComputeProjections;
}

export function savePermalink(): SavePermalink {
  return { type: ActionType.SavePermalink };
}

export function loadPermalink(id: string): LoadPermalink {
  return { type: ActionType.LoadPermalink, id };
}

export function setTitle(title: string): SetDatasetAnnotations {
  return {
    type: ActionType.SetDatasetAnnotations,
    annotations: new DatasetAnnotations({ title }),
  };
}

export function displayAlert(
  alert: UserAlert,
  durationMs?: number
): DisplayAlert {
  return {
    type: ActionType.DisplayAlert,
    alert: new UserAlert({
      id: PushIdGen.generate(),
      timestamp: Date.now(),
      severity: 'error',
      ...recordToObjNonNull(alert),
    }),
    durationMs: durationMs || 10000,
  };
}
