import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import React, { useEffect } from 'react';
import { useRouteMatch, match } from 'react-router-dom';
import { connect } from 'react-redux';

import { HERO_DATASETS, SHORTNAME_TO_DATASET } from 'src/constants';
import * as actions from 'src/actions';
import DatasetControlForm from './DatasetControlForm';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  nameTextField: {
    width: 250,
  },
}));

interface PremadeNebulaControlsProps {
  loadPermalink: (id: string) => void;
  setPath: (path: string) => void;
}

const PremadeNebulaControls = (props: PremadeNebulaControlsProps) => {
  const classes = useStyles();
  const routeMatch: match = useRouteMatch();
  const shortname = (routeMatch.params as any)['shortname'] || null;

  useEffect(() => {
    if (shortname) {
      const id = SHORTNAME_TO_DATASET[shortname]?.id || `n/${shortname}`;
      props.loadPermalink(id);
    }
  }, [routeMatch]);

  const [shortnameInput, setShortnameInput] = React.useState<string>(
    shortname || HERO_DATASETS[0].shortname
  );

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const path = `/n/${shortnameInput}`;
    props.setPath(path);
  };
  return (
    <DatasetControlForm onSubmit={onSubmit}>
      <FormControl variant="outlined">
        <Autocomplete
          freeSolo
          disableClearable
          options={_.keys(SHORTNAME_TO_DATASET)}
          getOptionLabel={(name) => {
            return SHORTNAME_TO_DATASET[name]?.title || name;
          }}
          renderInput={(params) => (
            <FormControl variant="outlined">
              <TextField
                {...params}
                id="premade-nebula-name-input"
                className={classes.nameTextField}
                label="Name"
                variant="outlined"
                InputProps={{ ...params.InputProps, type: 'search' }}
              />
            </FormControl>
          )}
          value={shortnameInput}
          onChange={(event: any, newValue: string | null) => {
            setShortnameInput(newValue);
          }}
        />
      </FormControl>
    </DatasetControlForm>
  );
};

export default connect(null, {
  loadPermalink: actions.loadPermalink,
})(PremadeNebulaControls);
