import { DisplayPoint } from 'src/types';

import { Theme, makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Item } from '@nebula/common';
import { primaryText } from 'src/util';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

export type PointLabelContent = FunctionComponent<{ point: DisplayPoint }>;

const useStyles = makeStyles((theme: Theme) => {
  return {
    contentWrapper: {
      margin: '8px 0 4px 8px',
      maxWidth: 300,
    },
    scrollablePrimaryText: {
      paddingBottom: '6px',
      color: theme.palette.getContrastText('#000'),
      display: 'block',
      lineHeight: '1rem',
      overflowWrap: 'anywhere',
      fontSize: '1rem',
    },
    thumbVertical: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    date: {
      fontFamily: '"Roboto", sans-serif',
      color: '#eee',
      fontSize: '0.85rem',
      lineHeight: '0.85rem',
    },
    contextLink: {
      fontSize: '1.00rem',
      color: theme.palette.secondary.light,
    },
  };
});

export const ContextLink = ({ href, children }: any) => {
  const classes = useStyles();
  return (
    <Link
      href={href}
      className={classes.contextLink}
      target="_blank"
      rel="noopener"
      variant="body2"
      color="secondary"
    >
      {children}
    </Link>
  );
};

export const CommonLabelContent: PointLabelContent = (props) => {
  const classes = useStyles();
  const item = props.point.item as Item;
  return (
    <Grid container direction="column" className={classes.contentWrapper}>
      <Grid item>
        <Typography
          className={classes.scrollablePrimaryText}
          color="inherit"
          component="div"
        >
          <Scrollbars
            autoHeight
            autoHeightMin="1.2rem"
            autoHeightMax="4.2rem"
            thumbMinSize={4}
            renderThumbVertical={(props) => (
              <div {...props} className={classes.thumbVertical} />
            )}
          >
            {primaryText(item)}
          </Scrollbars>
        </Typography>
      </Grid>
      <Grid item>
        {item.timestamp && (
          <div className={classes.date}>
            {new Date(item.timestamp).toDateString()}
          </div>
        )}
      </Grid>
      <Grid item container direction="row" spacing={1}>
        {React.Children.map(props.children, (child) => {
          return <Grid item>{child}</Grid>;
        })}
      </Grid>
    </Grid>
  );
};
