import * as React from 'react';
import * as ReactDOM from 'react-dom';

import Root from './components/Root';
import './index.css';
import { inDevMode } from './util';
import immutable from 'immutable';

if (inDevMode) {
  console.log('In development mode.');
  const installDevTools = require('immutable-devtools');
  installDevTools(immutable);
}

ReactDOM.render(<Root />, document.getElementById('app'));
