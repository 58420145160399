export enum ItemType {
  FreeText = 'FREE_TEXT',
  RedditPost = 'REDDIT_POST',
  RedditComment = 'REDDIT_COMMENT',
  Tweet = 'TWEET',
  RoamNode = 'ROAM_NODE',
}

export interface Item {
  type: ItemType;
  canonicalUrl?: string;
  author?: string;
  timestamp?: string;
}

export interface FreeText extends Item {
  type: ItemType.FreeText;
  body?: string;
}

export function isFreeText(item: Item): item is FreeText {
  return item.type === ItemType.FreeText;
}

export interface RedditItem extends Item {
  type: ItemType.RedditPost | ItemType.RedditComment;
  body?: string;
  score?: number;
}

export function isRedditItem(item: Item): item is RedditItem {
  return (
    item.type === ItemType.RedditPost || item.type === ItemType.RedditComment
  );
}

//

export interface RedditPost extends RedditItem {
  type: ItemType.RedditPost;
  title?: string;
  submissionUrl?: string;
  numComments?: number;
}

export function isRedditPost(item: Item): item is RedditPost {
  return item.type === ItemType.RedditPost;
}

export interface RedditComment extends RedditItem {
  type: ItemType.RedditComment;
}

export function isRedditComment(item: Item): item is RedditComment {
  return item.type === ItemType.RedditComment;
}

//

export interface Tweet extends Item {
  type: ItemType.Tweet;
  tweetId?: string;
  body?: string;
  author?: string;
  authorUrl?: string;
  authorNumFollowers?: number;
  sender?: string;
  numFavorites?: number;
  isRetweet?: boolean;
}

export function isTweet(item: Item): item is Tweet {
  return item.type === ItemType.Tweet;
}

export interface RoamNode extends Item {
  type: ItemType.RoamNode;
  uid?: string;
  body?: string;
  pageTitle?: string;
  dbName?: string;
}

export function isRoamNode(item: Item): item is RoamNode {
  return item.type === ItemType.RoamNode;
}
