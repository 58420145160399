import {
  Key,
  Dataset,
  ProjectionParams,
  SavableState,
  ProjectionType,
  Projection,
  DatasetAnnotations,
  UserAlert,
  FreeformDatasetInput,
} from 'src/types';
import WebpackWorker from 'worker-loader!*';
import { SplitMode } from './parsing/parse';
import { List } from 'immutable';

export enum ActionType {
  FirebaseInitComplete = 'FIREBASE_INIT_COMPLETE',

  LoadFreeTextDataset = 'LOAD_FREE_TEXT_DATASET',
  LoadFreeTextDatasetComplete = 'LOAD_FREE_TEXT_DATASET_COMPLETE',
  LoadFreeTextDatasetFailed = 'LOAD_FREE_TEXT_DATASET_FAILED',

  FetchDataset = 'FETCH_DATASET',
  FetchDatasetComplete = 'FETCH_DATASET_COMPLETE',
  FetchDatasetFailed = 'FETCH_DATASET_FAILED',

  SetDatasetAnnotations = 'SET_DATASET_ANNOTATIONS',

  EmbedWorkerStart = 'EMBED_WORKER_START',
  EmbedWorkerEmbed = 'EMBED_WORKER_EMBED',
  EmbedWorkerEmbedComplete = 'EMBED_WORKER_EMBED_COMPLETE',
  EmbedWorkerEmbedFailed = 'EMBED_WORKER_EMBED_FAILED',

  ComputeEmbedding = 'COMPUTE_EMBEDDING',
  ComputeEmbeddingFailed = 'COMPUTE_EMBEDDING_FAILED',
  ComputeEmbeddingComplete = 'COMPUTE_EMBEDDING_COMPLETE',

  SetProjectionParams = 'SET_PROJECTION_PARAMS',

  ComputeProjections = 'COMPUTE_PROJECTIONS',
  ComputeProjectionFailed = 'COMPUTE_ROJECTION_FAILED',
  ComputeProjectionComplete = 'COMPUTE_PROJECTION_COMPLETE',

  PointClicked = 'POINT_CLICKED',
  HoveredPointChanged = 'HOVERED_POINT_CHANGED',

  SavePermalink = 'SAVE_PERMALINK',
  SavePermalinkFailed = 'SAVE_PERMALINK_FAILED',
  SavePermalinkComplete = 'SAVE_PERMALINK_COMPLETE',

  LoadPermalink = 'LOAD_PERMALINK',
  LoadPermalinkFailed = 'LOAD_PERMALINK_FAILED',
  LoadPermalinkComplete = 'LOAD_PERMALINK_COMPLETE',

  DisplayAlert = 'DISPLAY_ALERT',
  DisplayAlertEnd = 'DISPLAY_ALERT_END',
}

export interface Action {
  type: ActionType;
}

export interface FailureAction extends Action {
  error: Error;
}

export function isFailureAction(action: Action): action is FailureAction {
  return (action as FailureAction).error !== undefined;
}

//

export interface FirebaseInitComplete extends Action {
  type: ActionType.FirebaseInitComplete;
  firebaseApp: firebase.app.App;
}

//

export interface UpdateDatasetAction extends Action {
  // Deep-merged into current dataset
  dataset?: Dataset;
  serializedDataset?: string;
}

export function isUpdateDataset(action: Action): action is UpdateDatasetAction {
  return (
    (action as UpdateDatasetAction).dataset !== undefined ||
    (action as UpdateDatasetAction).serializedDataset !== undefined
  );
}

//

export interface LoadFreeTextDataset extends Action {
  type: ActionType.LoadFreeTextDataset;
  datasetInputs: List<FreeformDatasetInput>;
}

export interface LoadFreeTextDatasetComplete extends UpdateDatasetAction {
  type: ActionType.LoadFreeTextDatasetComplete;
}

export interface LoadFreeTextDatasetFailed extends FailureAction {
  type: ActionType.LoadFreeTextDatasetFailed;
}

//

export interface FetchDataset extends Action {
  type: ActionType.FetchDataset;
  path: string;
}

export interface FetchDatasetComplete extends UpdateDatasetAction {
  type: ActionType.FetchDatasetComplete;
}

export interface FetchDatasetFailed extends FailureAction {
  type: ActionType.FetchDatasetFailed;
}

//

export interface SetDatasetAnnotations extends Action {
  type: ActionType.SetDatasetAnnotations;
  annotations: DatasetAnnotations;
}

//

export interface EmbedWorkerStart extends Action {
  type: ActionType.EmbedWorkerStart;
  worker: WebpackWorker;
}

export interface EmbedWorkerEmbed extends Action {
  type: ActionType.EmbedWorkerEmbed;
  id: Key;
  text: string[];
}

export interface EmbedWorkerEmbedFailed extends Action {
  type: ActionType.EmbedWorkerEmbedFailed;
  id: Key;
  error: string;
}

export interface EmbedWorkerEmbedComplete extends Action {
  type: ActionType.EmbedWorkerEmbedComplete;
  id: Key;
  embeddings: number[][];
}

//

export interface ComputeEmbedding extends Action {
  type: ActionType.ComputeEmbedding;
  dataset?: Dataset;
}

export interface ComputeEmbeddingFailed extends FailureAction {
  type: ActionType.ComputeEmbeddingFailed;
}

export interface ComputeEmbeddingComplete extends UpdateDatasetAction {
  type: ActionType.ComputeEmbeddingComplete;
}

//

export interface SetProjectionParams extends Action {
  type: ActionType.SetProjectionParams;
  params: ProjectionParams;
}

//

export interface ComputeProjections extends Action {
  type: ActionType.ComputeProjections;
  projectionTypes: ProjectionType[];
}

export interface ComputeProjectionFailed extends FailureAction {
  type: ActionType.ComputeProjectionFailed;
}

export interface ComputeProjectionComplete extends Action {
  type: ActionType.ComputeProjectionComplete;
  projectionType: ProjectionType;
  projections: List<Projection>;
}

//

export interface PointClicked extends Action {
  type: ActionType.PointClicked;
  key: Key;
}

export interface HoveredPointChanged extends Action {
  type: ActionType.HoveredPointChanged;
  key: Key;
}

//

export interface SavePermalink extends Action {
  type: ActionType.SavePermalink;
}

export interface SavePermalinkFailed extends FailureAction {
  type: ActionType.SavePermalinkFailed;
}

export interface SavePermalinkComplete extends Action {
  type: ActionType.SavePermalinkComplete;
  url: string;
  savedState: SavableState;
}

//

export interface LoadPermalink extends Action {
  type: ActionType.LoadPermalink;
  id: string;
}

export interface LoadPermalinkFailed extends FailureAction {
  type: ActionType.LoadPermalinkFailed;
}

export interface LoadPermalinkComplete extends Action {
  type: ActionType.LoadPermalinkComplete;
  id: string;
  savedState: SavableState;
}

//

export interface DisplayAlert extends Action {
  type: ActionType.DisplayAlert;
  alert: UserAlert;
  durationMs?: number;
}

export interface DisplayAlertEnd extends Action {
  type: ActionType.DisplayAlertEnd;
  id: string;
}
