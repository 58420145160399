import chroma from 'chroma-js';
import stringify from 'fast-json-stable-stringify';
import { Location } from 'history';
import Immutable, { Map, Seq, Set, Collection, List } from 'immutable';
import _ from 'lodash';
import {
  Item,
  isFreeText,
  isRedditPost,
  isRedditComment,
  isTweet,
  isRoamNode,
} from '@nebula/common';
import * as QueryString from 'query-string';
import transitImmutable from 'transit-immutable-js';
import { v5 as uuidv5, v4 as uuidv4 } from 'uuid';

import {
  Table,
  Datapoint,
  Dataset,
  ProjectionView,
  ProjectionParams,
  SavableState,
  Projection,
  ProjectionType,
  Key,
  DatasetAnnotations,
  DisplayStatus,
} from 'src/types';

export const UUIDV5_NAMESPACE = uuidv5('io.grady.nebula', uuidv5.DNS);

export function getMode(): string {
  return process.env.NODE_ENV;
}

export const mode: string = process.env.NODE_ENV;
export const inDevMode: boolean = mode === 'development';
export const inProdMode: boolean = mode === 'production';

function stringHash(str: string) {
  var hash = 5381,
    i = str.length;

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0;
}

export function key(object?: any): string {
  if (object) {
    // Dangerous because I don't plan to update the key every time the value
    // changes:
    // return uuidv5(stringify(object), UUIDV5_NAMESPACE);
    return uuidv4();
  } else {
    return uuidv4();
  }
}

export function keyTable<V>(objects: V[]): Table<V> {
  const valueSeq = Seq.Indexed(objects);
  const keySeq = valueSeq.map(key);
  return Map(keySeq.zip(valueSeq));
}

export function transformValues<I, O>(
  fn: (values: Array<I>) => Array<O>,
  table: Table<I>
): Table<O> {
  const keys: Seq.Indexed<string> = table.keySeq();
  const inValues: Array<I> = table.valueSeq().toArray();
  const outValues: Array<O> = fn(inValues);
  return Map(keys.zip(Seq.Indexed(outValues)));
}

export async function transformValuesAsync<I, O>(
  fn: (values: Array<I>) => Promise<Array<O>>,
  table: Table<I>
): Promise<Table<O>> {
  const keys: Seq.Indexed<string> = table.keySeq();
  const inValues: Array<I> = table.valueSeq().toArray();
  const outValues: Array<O> = await fn(inValues);
  return Map(keys.zip(Seq.Indexed(outValues)));
}

const transitImmutableWithRecords = transitImmutable.withRecords([
  Datapoint,
  Dataset,
  ProjectionView,
  ProjectionParams,
  SavableState,
]);

export function serializeImmutable(immutableObj: any): string {
  return transitImmutableWithRecords.toJSON(immutableObj);
}

export function deserializeImmutable(immutableSerialized: string): any {
  return transitImmutableWithRecords.fromJSON(immutableSerialized);
}

export function serializeState(state: SavableState): string {
  return JSON.stringify(state.toJS());
}

export function deserializeState(serialized: string): SavableState {
  const obj = JSON.parse(serialized);
  const immutableContents = Immutable.fromJS(obj, stateReviver);
  return new SavableState(immutableContents);
}

// This needs to be maintained to have a clause for every (nested) element of a
// SavableState that needs to be serialized as anything but a primitive type, a
// Map, or a List.
function stateReviver(
  key: string | number,
  sequence: Collection.Keyed<string, any> | Collection.Indexed<any>,
  path: Array<string | number>
): any {
  if (key === 'dataset') {
    return new Dataset(sequence);
  } else if (key === 'directPoints') {
    return Map(sequence);
  } else if (path.slice(-2)[0] === 'directPoints') {
    return new Datapoint(sequence);
  } else if (
    (path.slice(-2)[0] === 'dataset' && path.slice(-1)[0] === 'annotations') ||
    (path.slice(-3)[0] === 'childDatasets' &&
      path.slice(-1)[0] === 'annotations')
  ) {
    return new DatasetAnnotations(sequence);
  } else if (key === 'childDatasets') {
    return List(sequence);
  } else if (path.slice(-2)[0] === 'childDatasets') {
    return new Dataset(sequence);
  } else if (key === 'item') {
    return sequence.toObject();
  } else if (key === 'projectionParams') {
    return new ProjectionParams(sequence);
  } else if (key === 'projectionView') {
    return new ProjectionView(sequence);
  } else if (key === 'ajarPoints') {
    return Set(sequence);
  } else if (key === 'openPoints') {
    return Set(sequence);
  } else if (key === 'openPoints') {
    return Set(sequence);
  } else if (Immutable.isKeyed(sequence)) {
    return Map(sequence);
  } else if (Immutable.isIndexed(sequence)) {
    return List(sequence);
  }
}

export function primaryText(item: Item): string {
  if (isFreeText(item)) {
    return item.body;
  } else if (isRedditPost(item)) {
    return item.title;
  } else if (isRedditComment(item)) {
    return item.body;
  } else if (isTweet(item)) {
    return item.body;
  } else if (isRoamNode(item)) {
    return item.body;
  } else if ('body' in item) {
    return (item as any).body;
  }
  console.assert(false, `No primaryText defined for item: ${item}`);
}

export function defaultColorForItem(item: Item): string {
  if (isRedditPost(item)) {
    return '#ED001C'; // Reddit red
  } else if (isRedditComment(item)) {
    return '#0077D6'; // Reddit alien blue
  } else if (isTweet(item)) {
    return '#1DA1F2'; // Twitter blue
  } else {
    // return '#3DA7FD'; // Nebula blue
    return null;
  }
}

export const displayStatusForPoint = (
  key: Key,
  projectionView: ProjectionView
): DisplayStatus => {
  if (projectionView.openPoints.contains(key)) {
    return DisplayStatus.Open;
  } else if (key === projectionView.hoveredPoint) {
    return DisplayStatus.Ajar;
  } else if (projectionView.ajarPoints.contains(key)) {
    return DisplayStatus.Ajar;
  }
  return DisplayStatus.Default;
};

// From https://stackoverflow.com/a/4382138/1028969
export const kellyColors = [
  '#817066', // Medium Gray
  '#A6BDD7', // Very Light Blue
  '#C10020', // Vivid Red
  '#FFB300', // Vivid Yellow
  '#803E75', // Strong Purple
  '#FF6800', // Vivid Orange
  '#CEA262', // Grayish Yellow
  // The following don't work well for people with defective color vision
  '#007D34', // Vivid Green
  '#F6768E', // Strong Purplish Pink
  '#00538A', // Strong Blue
  '#FF7A5C', // Strong Yellowish Pink
  '#53377A', // Strong Violet
  '#FF8E00', // Vivid Orange Yellow
  '#B32851', // Strong Purplish Red
  '#F4C800', // Vivid Greenish Yellow
  '#7F180D', // Strong Reddish Brown
  '#93AA00', // Vivid Yellowish Green
  '#593315', // Deep Yellowish Brown
  '#F13A13', // Vivid Reddish Orange
  '#232C16', // Dark Olive Green,
];

export const distinctColors: string[] = [
  // Leaving greens disabled to avoid R/G colorblindness issues at least
  // deepskyblue
  '#00bfff',
  // red
  '#ff0000',
  // fuchsia
  '#ff00ff',
  // mediumseagreen
  // '#3cb371',
  // palegoldenrod
  '#eee8aa',
  // blue
  '#3333ff',
  // darkorange
  '#ff8c00',
  // yellowgreen
  // '#9acd32',
  // sienna
  '#a0522d',
  // aqua
  '#00ffff',
  // deeppink
  '#ff1493',
  // springgreen
  // '#00ff7f',
  // thistle
  '#d8bfd8',
  // royalblue
  '#4169e1',
  // gold
  '#ffd700',
  // violet
  '#ee82ee',
];

export function nDistinctColors(numColors: number): string[] {
  if (numColors > distinctColors.length) {
    if (inDevMode) {
      console.log(
        'numColors is greater than available unique colors. Falling back to',
        'HSL color wheel.'
      );
    }
    const colors: string[] = [];
    for (let i = 0; i < numColors; i++) {
      colors.push(chroma.hsl((i * 360) / numColors, 1, 0.6).toString());
    }
    return _.sortBy(colors, (colorStr) => stringHash(colorStr));
  } else {
    return distinctColors.slice(0, numColors);
  }
}

export class PushIdGen {
  // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
  private static lastPushTime = 0;

  // Modeled after base64 web-safe chars, but ordered by ASCII.
  private static PUSH_CHARS =
    '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

  // We generate 72-bits of randomness which get turned into 12 characters and appended to the
  // timestamp to prevent collisions with other clients.  We store the last characters we
  // generated because in the event of a collision, we'll use those same characters except
  // "incremented" by one.
  private static lastRandChars: number[] = [];

  // Generates chronologically orderable unique string one by one
  public static generate(): string {
    var now = new Date().getTime();
    var duplicateTime = now === PushIdGen.lastPushTime;
    PushIdGen.lastPushTime = now;

    var timeStampChars = new Array(8);
    for (var i = 7; i >= 0; i--) {
      timeStampChars[i] = PushIdGen.PUSH_CHARS.charAt(now % 64);
      // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
      now = Math.floor(now / 64);
    }
    if (now !== 0)
      throw new Error('We should have converted the entire timestamp.');

    var id = timeStampChars.join('');

    if (!duplicateTime) {
      for (i = 0; i < 12; i++) {
        PushIdGen.lastRandChars[i] = Math.floor(Math.random() * 64);
      }
    } else {
      // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
      for (i = 11; i >= 0 && PushIdGen.lastRandChars[i] === 63; i--) {
        PushIdGen.lastRandChars[i] = 0;
      }
      PushIdGen.lastRandChars[i]++;
    }
    for (i = 0; i < 12; i++) {
      id += PushIdGen.PUSH_CHARS.charAt(PushIdGen.lastRandChars[i]);
    }
    if (id.length != 20) throw new Error('Length should be 20.');

    return id;
  }
}

export function permalinkIdToLink(id: string): string {
  if (inDevMode) return `localhost:1234/${id}`;
  return `nebulate.ai/${id}`;
}

function _localStorageAvailable() {
  var storage;
  try {
    storage = window['localStorage'];
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export const localStorageAvailable = _.memoize(_localStorageAvailable);

export const offscreenCanvasSupported = (): boolean => {
  return 'OffscreenCanvas' in window;
};

// Returns whether set was successful
export function setLocalStorageFlag(key: string, value: boolean): boolean {
  if (!localStorageAvailable) return false;
  localStorage.setItem(key, value ? 'true' : 'false');
  return true;
}

export function getLocalStorageFlag(
  key: string,
  defaultValue?: boolean
): boolean {
  if (!localStorageAvailable()) return defaultValue;
  const result = localStorage.getItem(key);
  return result === null ? defaultValue : result === 'true';
}

export function deleteEmbeddings(dataset: Dataset): Dataset {
  return dataset.update('directPoints', (points: Table<Datapoint>) => {
    return points.map((p: Datapoint) => p.delete('embedding'));
  });
}

export function mergeProjection(
  dataset: Dataset,
  projectionTable: Table<Projection>,
  type: ProjectionType
): Dataset {
  return dataset.update('directPoints', (points: Table<Datapoint>) => {
    return points.map((point: Datapoint, key: Key) => {
      return point.update(
        'projections',
        (projections: Map<ProjectionType, Projection>) =>
          projections.set(type, projectionTable.get(key))
      );
    });
  });
}

export function recordToObjNonNull(record: any): any {
  return _.pickBy(record.toObject(), _.identity);
}

export function extractNumPoints(location: Location): number {
  return parseInt(QueryString.parse(location.search)['n'] as string) || null;
}
