export const firebaseConfig = {
  apiKey: 'AIzaSyCJQWfrPGaaHcdKrokw1mB8R82kK3jdGcE',
  authDomain: 'nebula-0.firebaseapp.com',
  databaseURL: 'https://nebula-0.firebaseio.com',
  projectId: 'nebula-0',
  storageBucket: 'nebula-0.appspot.com',
  messagingSenderId: '1005468548465',
  appId: '1:1005468548465:web:87d4dc1c38f8ff8ba94ad0',
  measurementId: 'G-XW7RXWT675',
};
