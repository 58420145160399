import React, { FunctionComponent } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    panel: {
      position: 'relative',
      right: 8,
      top: 15,
      padding: 15,
      zIndex: 5200,
      backgroundColor: 'rgba(0,0,0,.4)',
    },
    row: {
      fontFamily: '"Open sans", sans-serif',
      textAlign: 'right',
      color: '#fff',
      '&:not(:last-child)': {
        marginBottom: 10,
      },
    },
    control: {
      display: 'inline-block',
      padding: '2px 5px',
      textAlign: 'center',
      fontFamily: '"Cutive Mono", monospace',
      fontWeight: 'bold',
      color: '#111',
      background: '#eff0f2',
      boxShadow:
        'inset 0 0 25px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9, 0 2px 3px #000',
      borderRadius: 4,
    },
  };
});

interface ControlsInfoPanelProps {}

const ControlsInfoPanel: FunctionComponent<ControlsInfoPanelProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <Paper className={classes.panel}>
      <div className={classes.row}>
        Open point <span className={classes.control}>Click</span>
      </div>
      <div className={classes.row}>
        Move <span className={classes.control}>Click & drag</span>
      </div>
      <div className={classes.row}>
        Zoom <span className={classes.control}>Scroll</span>
      </div>
    </Paper>
  );
};

export default ControlsInfoPanel;
